import React from 'react'
import { useParams } from 'react-router-dom'
import SideBar from './SideBar'
import { useState, useEffect } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
function UserCaseStudy() {
    const { id } = useParams()
    const [CaseStudyData, setCaseStudyData] = useState([])

    const getCasedetails = async () => {
        try {
            let res = await axios.get(`https://diabapi.ayurai.in/api/data/getOneCaseSheet/${id}`);
            if (res.status === 200 && res.data) {
                setCaseStudyData(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getCasedetails();
    }, [id]);

    if (!CaseStudyData.PatientDetails || !CaseStudyData.DiseaseHistory || !CaseStudyData.PersonalHistory) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    }
    return <>
        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
                <div className='Main-contents'>
                    <div >
                        {CaseStudyData &&
                            <div className='precription-container'>
                                <div className='header'>
                                    <h1>Case Sheet</h1>
                                </div>
                                <div className='patient-information'>
                                    <p id='Patient-Information'>Patient Information</p>

                                    <div className='information'>
                                        <div>
                                            <p id='info-t'>Name</p>
                                            <p>{CaseStudyData?.PatientDetails?.Name}</p>
                                        </div>
                                        <div>
                                            <p id='info-t'>Age</p>
                                            <p>{CaseStudyData?.PatientDetails?.Age}</p>
                                        </div>
                                        <div>
                                            <p id='info-t'>Gender</p>
                                            <p>{CaseStudyData?.PatientDetails?.Gender}</p>
                                        </div>
                                        <div>
                                            <p id='info-t'>Weight</p>
                                            <p>{CaseStudyData?.PatientDetails?.Weight}</p>
                                        </div>
                                        <div>
                                            <p id='info-t'>Height</p>
                                            <p>{CaseStudyData?.PatientDetails?.Height}</p>
                                        </div>
                                        <div>
                                            <p id='info-t'>
                                                Marital Status
                                            </p>
                                            <p>{CaseStudyData?.PatientDetails?.
                                                Maritalstatus
                                            }</p>
                                        </div>
                                        <div>
                                            <p id='info-t'>
                                                Occupation</p>
                                            <p>{CaseStudyData?.PatientDetails?.
                                                Occupation}</p>
                                        </div>
                                        <div>
                                            <p id='info-t'>

                                                Present Residence
                                            </p>
                                            <p>{CaseStudyData?.PatientDetails?.

                                                PresentResidence
                                            }</p>
                                        </div>
                                        <div>
                                            <p id='info-t'>

                                                BirthPlace

                                            </p>
                                            <p>{CaseStudyData?.PatientDetails?.

                                                BirthPlace

                                            }</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p id='Patient-Information'>Disease History</p>
                                        <div className='information'>
                                            <div>
                                                <p id='info-t'>Complaints</p>
                                                <div>

                                                    <table id='table1' >
                                                        <thead>
                                                            <tr>
                                                                <th>Complaint Name</th>
                                                                <th>Duration</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {CaseStudyData &&
                                                                Object.values(CaseStudyData?.DiseaseHistory?.complaints).filter((e) => {
                                                                    if (e.selected === "Yes") {
                                                                        return e
                                                                    }
                                                                }).map((e) => {
                                                                    return <tr>
                                                                        <td>{e?.complaintName}</td>
                                                                        <td>{e?.duration}</td>
                                                                    </tr>
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div>
                                                <p id='info-t'>Other Complaints</p>
                                                <div>

                                                    <table id='table1' >
                                                        <thead>
                                                            <tr>
                                                                <th>Complaint Name</th>
                                                                <th>Duration</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                Object.values(CaseStudyData?.DiseaseHistory?.Othercomplaints
                                                                ).filter((e) => {
                                                                    if (e.selected === "Yes") {
                                                                        return e
                                                                    }
                                                                }).map((e) => {
                                                                    return <tr>
                                                                        <td>{e?.complaintName}</td>
                                                                        <td>{e?.duration}</td>
                                                                    </tr>
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div>
                                                <p id='info-t'>Other Diseases</p>
                                                <div>

                                                    <table id='table1' >
                                                        <thead>
                                                            <tr>
                                                                <th>Diseases Name</th>
                                                                <th>Duration</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {CaseStudyData &&
                                                                Object.values(CaseStudyData?.DiseaseHistory?.diseases

                                                                ).filter((e) => {
                                                                    if (e.selected === "Yes") {
                                                                        return e
                                                                    }
                                                                }).map((e) => {
                                                                    return <tr>
                                                                        <td>{e?.complaintName
                                                                        }</td>
                                                                        <td>{e?.duration}</td>
                                                                    </tr>
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {CaseStudyData.DiseaseHistory.HistoryofPastillness !== '' ? <div>
                                                <p id='info-t'>H/o Past illness</p>
                                                <div>
                                                    <p>{CaseStudyData.DiseaseHistory.HistoryofPastillness}</p>
                                                </div>
                                            </div> : <></>}
                                        </div>
                                    </div>
                                    <div>
                                        <p id='Patient-Information'>PersonalHistory</p>
                                        <div className='information'>
                                            <div>
                                                <p id='info-t'>
                                                    Diet
                                                </p>
                                                <div style={{ display: "flex", justifyContent: "space-around" }}>     <div>
                                                    <p id='info-t'>
                                                        Diet Type
                                                    </p>
                                                    <div style={{ display: "flex", gap: '10px' }}> {CaseStudyData?.PersonalHistory?.Diet
                                                        ?.DietType === 'Vegetarian' ? <img src={'https://aiwellassets.s3.ap-south-1.amazonaws.com/images/Veg.png'} style={{ width: "1.5rem" }} /> : <img src={'https://aiwellassets.s3.ap-south-1.amazonaws.com/images/Non-Veg.png'} style={{ width: "18px" }} />}
                                                        <p>{CaseStudyData?.PersonalHistory?.Diet
                                                            ?.DietType
                                                        }</p>
                                                    </div>

                                                </div>  <div>
                                                        <p id='info-t'>
                                                            Agni
                                                        </p>
                                                        <p>
                                                            {CaseStudyData?.PersonalHistory?.Diet
                                                                ?.Agni}
                                                        </p>
                                                    </div>  </div>



                                            </div>
                                            <div>
                                                <p id='info-t'>Habits</p>
                                                <div>

                                                    <table id='table1' >
                                                        <thead>
                                                            <tr>
                                                                <th>Habits Name</th>
                                                                <th>
                                                                    frequency
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {CaseStudyData &&
                                                                Object.values(CaseStudyData?.PersonalHistory?.Habits).filter((e) => {
                                                                    if (e.selected === "Yes") {
                                                                        return e
                                                                    }
                                                                }).map((e) => {
                                                                    return <tr>
                                                                        <td>{e?.habitName}</td>
                                                                        <td>{e?.frequency}</td>
                                                                    </tr>
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {CaseStudyData && Object.values(CaseStudyData?.PersonalHistory?.Addictions).filter((e) => {
                                                if (e.selected === "Yes") {
                                                    return e
                                                }
                                            }).length > 0 ? <div>
                                                <p id='info-t'>Addictions</p>
                                                <div>

                                                    <table id='table1' >
                                                        <thead>
                                                            <tr>
                                                                <th>Addiction Name</th>
                                                                <th>Frequency</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {CaseStudyData &&
                                                                Object.values(CaseStudyData?.PersonalHistory?.Addictions).filter((e) => {
                                                                    if (e.selected === "Yes") {
                                                                        return e
                                                                    }
                                                                }).map((e) => {
                                                                    return <tr>
                                                                        <td>{e?.AddictionName}</td>
                                                                        <td>{e?.frequency}</td>
                                                                    </tr>
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> : <></>}
                                            <div>
                                                <p id='info-t'>
                                                    Physical Activity
                                                </p>
                                                <div>
                                                    <p>{CaseStudyData?.PersonalHistory?.
                                                        PhysicalActivity
                                                    }</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p id='info-t'>
                                                    Sleep
                                                </p>
                                                <div style={{ display: "flex", justifyContent: "space-evenly", backgroundColor: "white", padding: '1rem', margin: '5px', borderRadius: "10px" }}>
                                                    <div style={{ fontWeight: "600", display: "flex", flexDirection: "column", gap: '1rem' }}>
                                                        <p>Quality of Sleep</p>
                                                        <p>Diwaswapna (Day Sleep)</p>
                                                        <p>Ratrijagarana</p>
                                                    </div>

                                                    <div style={{ display: "flex", flexDirection: "column", gap: '1rem', alignItems: 'center' }}>
                                                        <p>{CaseStudyData?.PersonalHistory?.Sleep?.
                                                            QualityOFSleep
                                                        }</p>
                                                        {CaseStudyData?.PersonalHistory?.Sleep?.Diwaswapna === 'Yes' ? <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/images/Tick.png' style={{ width: "22px" }}>
                                                        </img> : <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/images/Wrong.png" style={{ width: "22px" }}></img>}
                                                        {CaseStudyData?.PersonalHistory?.Sleep?.
                                                            Ratrijagarana
                                                            === 'Yes' ? <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/images/Tick.png' style={{ width: "22px" }}>
                                                        </img> : <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/images/Wrong.png" style={{ width: "22px" }}></img>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p id='info-t'>Occupational History</p>
                                                <div style={{ display: "flex", margin: '1rem', gap: "5rem" }}>
                                                    <div>
                                                        <p id='info-t'>
                                                            Physical
                                                        </p>
                                                        <p>{CaseStudyData?.PersonalHistory?.OccupationalHistory?.Physical

                                                        }</p>
                                                    </div>
                                                    <div>
                                                        <p id='info-t'>
                                                            Mental
                                                        </p>
                                                        <p>{CaseStudyData?.PersonalHistory?.OccupationalHistory?.
                                                            Mental

                                                        }</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p id='info-t'>Bowel</p>
                                                <div style={{ display: "flex", margin: '1rem', gap: "5rem" }}>
                                                    <div>
                                                        <p id='info-t'>
                                                            Bowel Movements
                                                        </p>
                                                        <p>{CaseStudyData?.PersonalHistory?.Bowel?.
                                                            BowelMovements


                                                        }</p>
                                                    </div>
                                                    <div>
                                                        <p id='info-t'>
                                                            Koshta
                                                        </p>
                                                        <p>{CaseStudyData?.PersonalHistory?.Bowel?.Koshta

                                                        }</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p id='info-t'>
                                                    Micturition
                                                </p>
                                                <p>{CaseStudyData?.PersonalHistory?.
                                                    Micturition
                                                }</p>
                                            </div>
                                            {CaseStudyData?.PatientDetails?.Gender != 'Male'
                                                ?
                                                <div>
                                                    <p id='info-t'>Gynecological</p>
                                                    <div style={{ display: "flex", justifyContent: "space-evenly", backgroundColor: "white", padding: '1rem', margin: '5px', borderRadius: "10px" }}>
                                                        <div style={{ fontWeight: "600", display: "flex", flexDirection: "column", gap: '1rem' }}>
                                                            <p>
                                                                Menstrual Cycle
                                                            </p>
                                                            <p>
                                                                Menstrual Flow
                                                            </p>
                                                            <p>Menopause
                                                            </p>
                                                        </div>

                                                        <div style={{ display: "flex", flexDirection: "column", gap: '1rem', alignItems: 'center' }}>
                                                            <p>{CaseStudyData?.PersonalHistory?.Gynecological?.
                                                                MenstrualCycle
                                                            }</p>
                                                            <p>{CaseStudyData?.PersonalHistory?.Gynecological?.
                                                                MenstrualFlow}</p>
                                                            {CaseStudyData?.PersonalHistory?.Gynecological?.
                                                                Menopause

                                                                === 'Yes' ? <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/images/Tick.png' style={{ width: "22px" }}>
                                                            </img> : <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/images/Wrong.png" style={{ width: "22px" }}></img>}
                                                        </div>
                                                    </div>
                                                </div> : <></>
                                            }
                                            {CaseStudyData?.PatientDetails?.Gender != 'Male' ?
                                                <div>
                                                    <p id='info-t'>ObstectricalHistory
                                                    </p>
                                                    <div style={{ display: "flex", margin: '1rem', gap: "5rem" }}>
                                                        <div>
                                                            <p id='info-t'>
                                                                No Of Delivery

                                                            </p>
                                                            <p>{CaseStudyData?.PersonalHistory?.ObstectricalHistory?.
                                                                NoOfDelivery


                                                            }</p>
                                                        </div>
                                                        <div>
                                                            <p id='info-t'>

                                                                Type of Delivery

                                                            </p>
                                                            <p>{CaseStudyData?.PersonalHistory?.ObstectricalHistory?.TypeofDelivery

                                                            }</p>
                                                        </div>
                                                        <div>
                                                            <p id='info-t'>

                                                                Hysterectomy


                                                            </p>
                                                            <p>{CaseStudyData?.PersonalHistory?.ObstectricalHistory?.Hysterectomy

                                                            }</p>
                                                        </div>
                                                        <div>
                                                            <p id='info-t'>
                                                                Anyother
                                                            </p>
                                                            <p>{CaseStudyData?.PersonalHistory?.ObstectricalHistory?.Anyother

                                                            }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <></>}
                                            <div>
                                                <p id='info-t'>

                                                    Surgeries</p>
                                                <p>{CaseStudyData?.PersonalHistory?.Surgeries}</p>
                                            </div>


                                        </div>
                                        <div>
                                            <p id='Patient-Information'>Clinical Assessment</p>
                                            <div className='information'>
                                                <div>
                                                    <p id='info-t'>Prakriti</p>
                                                    <div style={{ display: "flex", justifyContent: "space-evenly", backgroundColor: "white", padding: '1rem', margin: '5px', borderRadius: "10px" }}>
                                                        <div style={{ fontWeight: "600", display: "flex", flexDirection: "column", gap: '1rem' }}>
                                                            <p>
                                                                Dominant
                                                            </p>
                                                            <p>
                                                                Assoicated
                                                            </p>
                                                            <p>VPK (Prakriti)
                                                            </p>
                                                        </div>

                                                        <div style={{ display: "flex", flexDirection: "column", gap: '1rem', alignItems: 'center' }}>
                                                            <p>{CaseStudyData?.ClinicalAssessment?.PrakritiDetails?.
                                                                Dominant
                                                            }</p>
                                                            <p>{CaseStudyData?.ClinicalAssessment?.PrakritiDetails?.
                                                                Dominant}</p>
                                                            <div style={{ width: "15rem" }}>
                                                                <p>Total = {(CaseStudyData?.ClinicalAssessment?.PrakritiDetails?.Vata + CaseStudyData?.ClinicalAssessment?.PrakritiDetails?.Pitta + CaseStudyData?.ClinicalAssessment?.PrakritiDetails?.Kapha)}</p>
                                                                <p>Vata = {CaseStudyData?.ClinicalAssessment?.PrakritiDetails?.Vata}; Pitta = {CaseStudyData?.ClinicalAssessment?.PrakritiDetails?.Pitta}; Kapha = {CaseStudyData?.ClinicalAssessment?.PrakritiDetails?.Kapha}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='information'>
                                                <div>
                                                    <p id='info-t'>Dosham</p>
                                                    <div style={{ display: "flex", justifyContent: "space-evenly", backgroundColor: "white", padding: '1rem', margin: '5px', borderRadius: "10px" }}>
                                                        <div style={{ fontWeight: "600", display: "flex", flexDirection: "column", gap: '1rem' }}>
                                                            <p>
                                                                Decreased
                                                            </p>
                                                            <p>
                                                                Increased
                                                            </p>
                                                            <p>VPK (Dosham)
                                                            </p>
                                                        </div>

                                                        <div style={{ display: "flex", flexDirection: "column", gap: '1rem', alignItems: 'center' }}>
                                                            <p>{CaseStudyData?.ClinicalAssessment?.Dosham?.
                                                                Decreased
                                                            }</p>
                                                            <p>{CaseStudyData?.ClinicalAssessment?.Dosham?.
                                                                Increased}</p>
                                                            <div style={{ width: "15rem" }}>
                                                                <p>Total = {(CaseStudyData?.ClinicalAssessment?.Dosham?.Vata + CaseStudyData?.ClinicalAssessment?.Dosham?.Pitta + CaseStudyData?.ClinicalAssessment?.Dosham?.Kapha)}</p>
                                                                <p>Vata = {CaseStudyData?.ClinicalAssessment?.Dosham?.Vata}; Pitta = {CaseStudyData?.ClinicalAssessment?.Dosham?.Pitta}; Kapha = {CaseStudyData?.ClinicalAssessment?.Dosham?.Kapha}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p id='Patient-Information'>Diagnosis</p>

                                            <div className='information'>
                                                <div>

                                                    <div >
                                                        <div >
                                                            <p id='info-t'>

                                                                Ayurveda Diagnosis

                                                            </p>
                                                            <p>
                                                                {CaseStudyData?.Diagnois?.
                                                                    AyurvedaDiagnois
                                                                }
                                                            </p>

                                                        </div>

                                                        <div >
                                                            <p id='info-t'>

                                                                Modern Diagnosis

                                                            </p>
                                                            <p>
                                                                {CaseStudyData?.Diagnois?.
                                                                    ModernDiagnois
                                                                }
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default UserCaseStudy