import React from 'react'
import SideBar from './SideBar'
import { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material'
import { getDatabase, ref, onValue, } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import { contextAPI } from './Context';
import axios from 'axios';
import { db } from './firebase'
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SearchOutlined } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useContext } from "react";
import { useParams } from 'react-router-dom';
import logo from './ayurailogofull.png'
import { toast, Zoom } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
function Prescription() {
  let data = useContext(contextAPI)
  const [inputs, setInputs] = useState([
    { medicine: '', dose: '', instruction: '', duration: '', quantity: '' },
  ]);

  const [lifestylechanges, setlifestylechanges] = useState('')
  const [included, setinclude] = useState('')
  const [avoided, setavoided] = useState('')
  const [Medicaldetails, setMedicaldetails] = useState({
    Complaints: "", lab: "", history: "", diagnosis: ""
  })
  const [checked, setchecked] = useState(false)
  const [Age, setage] = useState(0)
  const yoga = [{
    name: 'Bhujangasana',
    link: 'https://www.app.ayurai.io/yoga/Bhujangasana'
  }, {
    name: 'Paschimottanasana',
    link: 'https://www.app.ayurai.io/yoga/Paschimottanasana'
  }, {
    name: 'Setubandhasana',
    link: 'https://www.app.ayurai.io/yoga/Setubandhasana'
  }, {
    name: 'Shalabasana',
    link: 'https://www.app.ayurai.io/yoga/Shalabasana'
  }, {
    name: 'Shashankasana',
    link: 'https://www.app.ayurai.io/yoga/Shashankasana'
  }, {
    name: 'Tadasana',
    link: 'https://www.app.ayurai.io/yoga/Tadasana'
  }, {
    name: 'Trikosana',
    link: 'https://www.app.ayurai.io/yoga/Trikosana'
  }, {
    name: 'Urdhvahastana',
    link: 'https://www.app.ayurai.io/yoga/Urdhvahastana'
  }, {
    name: 'Ushtrasana',
    link: 'https://www.app.ayurai.io/yoga/Uttanpadasana'
  },
  {
    name: 'Uttanpadasana',
    link: 'https://www.app.ayurai.io/yoga/VipritNaukasana'
  }, {
    name: 'VipritNaukasana',
    link: 'https://www.app.ayurai.io/yoga/VipritNaukasana'
  }, {
    name: 'bawanmuktasana',
    link: 'https://www.app.ayurai.io/yoga/bawanmuktasana'
  },
  ]
  const Navigate = useNavigate()
  let { id } = useParams()
  let patientdetails = data.DoctorAppointment.filter((e) => {
    if (e.Data.appointmentID === id) {
        return e
    }
}).map((e) => { return e })
 
let alldatadetails = patientdetails[0]

  let patient = data.users.filter((e) => {
    if (e.UID === alldatadetails.UID) {
      return e
    }
  }).map((e) => { return e })

  let alldata = patient[0]

  var dob = new Date(`${alldata?.Data?.dob.split('/')[1].toString()}/${alldata?.Data?.dob.split('/')[0].toString()}/${alldata?.Data?.dob.split('/')[2].toString()}`);

  var month_diff = Date.now() - dob.getTime();

  var age_dt = new Date(month_diff);

  var year = age_dt.getUTCFullYear();


  var age = Math.abs(year - 1970);



  const handleAddField = () => {
    setInputs([...inputs, { medicine: '', dose: '', instruction: '', duration: '', quantity: '' }]);
  };
  let date = new Date()

  const date_format = new Date();
  let day = date_format.getDate();
  let month = date_format.getMonth() + 1;
  let Year = date_format.getFullYear();

  const handleInputChange = (index, inputValue, field) => {
    setInputs((prevInputs) => {
      const newInputs = [...prevInputs];
      newInputs[index][field] = inputValue;
      return newInputs;
    });
  };

  const handleMedicaldetails = (fieldName, value) => {
    setMedicaldetails(prev => ({
      ...prev,
      [fieldName]: value

    }))
  }

 
  const genereatepdf = async () => {
    if (checked) {
      const prescription = `<!DOCTYPE html>
    <html>
    <title>Online HTML Editor</title>
    <head>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Jost&family=Lato&family=Montserrat:wght@400;700&family=Oleo+Script&family=Open+Sans:wght@600&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;1,400&display=swap');
            .header{
                display:flex;
                align-items:center;
                padding:1rem;
                justify-content:space-between;
                border:1px solid silver;
                flex-direction:row-reverse;
                margin:1rem;
                margin-bottom:0px;
              
            }
            .header>img{
                margin-left:auto;
                width:7rem;
            }
          .doctor-details>p:nth-child(1){
              font-family: 'Poppins', sans-serif;
      font-weight: 600 !important;
      font-size: 20px;
          }
          .doctor-details>p{
            font-family: 'Poppins', sans-serif;
      font-weight:400 !important;
     
          }
          .doctor-details{
            padding:5px;
            display:flex;
        flex-direction:column;
            letter-spacing:2px;
            line-height:1.5rem;
          }
          .patient-information{
            margin-left:1rem;
            margin-right:1rem;
            padding:1rem;
            border:1px solid silver
          }
          #Patient-Information{
            font-family: 'Poppins', sans-serif;
         font-size:20px;
         color:#1d86c6;
            
      font-weight:700 !important;
          }
          .information{
     display: grid;
     grid-template-columns: repeat(2,1fr);
     padding:3rem;
     
    grid-gap:1rem;
    grid-template-rows: auto;
           
          }
          #info-t{
             font-size:16px;
         color:#1d86c6;
               font-family: 'Poppins', sans-serif;
      font-weight:600 !important;
          }
          .information>div>p{
                    font-family: 'Poppins', sans-serif;
      font-weight:400 !important;
          }
    
    body, h1, h2, h3, p, ul, li {
      margin: 0;
      padding: 0;
    }
    
    body, h1, h2, h3, p, ul, li {
      margin: 0;
      padding: 0;
    }
    
    table {
      width: 100%;
      border-collapse: collapse;
      border-radius: 8px;
      overflow: hidden;
      max-width: 800px;
      margin: 20px auto;
      background-color: #fff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    thead {
      background-color: #66c9a3;
      color:white;
      font-size: 16px;
    }
    
    th, td {
      padding: 12px 16px;
      text-align: left;
    }
 
    
    tbody tr {
      border-bottom: 1px solid #E3F1D5;
      background-color: #FFFFFF;
    }
  
    

          .diet{
            padding:3rem;
            display:flex;
            gap:1rem;
            flex-direction:column; 
          }
          #diet-t{
                  font-family: 'Poppins', sans-serif;
            color:green;
      font-weight:500 !important;
          }
          #diet-tl{
                font-family: 'Poppins', sans-serif;
            color:red;
      font-weight:500 !important;
          }
         
          .supplements{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      font-family: 'Poppins', sans-serif;
      font-weight:500 !important;
      margin:1rem;
      place-items:center;
      grid-gap:1rem;
      grid-template-rows: auto;
    }
    .supplement{
      padding:10px;
      }
      #doc-sign{
      width:8rem;
      }
      .doctor-footer{
      display:flex;
      width:auto;
      flex-direction:column;
      align-items:flex-end;
      justify-content:flex-end;
      }
      .doctor-footer>div{
  display:flex;
  justify-content:center;
  gap:5px;
  align-items:center;
  flex-direction:column;
      }
      .supplement-card{
         width:10rem;
      }
      .supplement-card>img{
      width:10rem;
      border-radius:8px
      }
      #disclaimer{
      margin-top:2rem;
      text-align:center;
      }
      ul {
        list-style-type: disc;
        list-style-position: inside;
      }
        </style>
    </head>
    <body>
    <div class='precription-container'>
        <div class='header'>
            <img src=${logo}> 
            </img>
            <div class='doctor-details'>
              <p>${data.Doctors.Data.name}</p>
              <p>B.A.M.S,M.D.(Ayu.)</p>
              <p>Ayurvedic physician</p>
            </div>
    </div>
      <div class='patient-information'>
        <p id='Patient-Information'>
          Patient Information
        </p>
        <div class='information'>
          <div>
            <p id='info-t'>
              Name
            </p>
            <p>${alldata?.Data?.firstname}   ${alldata?.Data?.lastname}</p>
          </div>
                <div>
            <p id='info-t'>
              Age
            </p>
            <p>${age}</p>
          </div> 
                <div>
            <p id='info-t'>
              Email
            </p>
            <p>${alldata?.Data?.email}</p>
          </div>
                <div>
            <p id='info-t'>
              Gender
            </p>
            <p>${alldata?.Data.gender}</p>
          </div>
            
        </div>
        <div>
        <p id='Patient-Information'>Medical Details<p>
        <div class='information'>
        <div>
          <p id='info-t'>
           Complaints
          </p>
          <p>${Medicaldetails.Complaints}</p>
        </div>
        <div>
          <p id='info-t'>
           Examination /Lab findings
          </p>
          <p>${Medicaldetails.lab}</p>
        </div>
              <div>
          <p id='info-t'>
            Medical history
          </p>
          <p>${Medicaldetails.history}</p>
        </div>
         <div>
          <p id='info-t'>
            Diagnosis
          </p>
          <p>${Medicaldetails.diagnosis}</p>
        </div>
        </div>
      </div>
        <div>
          <p id='Patient-Information'>
           List of Prescribed Medications
        </p>
        <table>
        <thead>
          <tr>
            <th>S.no</th>
            <th>Medicine Name</th>
            <th>Dose</th>
            <th>Quantity</th>
            <th>Duration</th>
            <th>instruction</th>
          </tr>
        </thead>
        <tbody>
        ${inputs.map((e, i) => {
        return `<tr key={i}>
             <td>${i + 1}</td>
             <td>${e.medicine}</td>
             <td>${e.dose}</td>
             <td>${e.quantity}</td>
             <td>${e.duration}</td>
             <td>${e.instruction}</td>  
              </tr>`
      }).join(" ")}
        </tbody>
      </table>
          
           <p id='Patient-Information'>
          Diet
        </p>
        <div class='diet'>
              <div >
                <p id='diet-t'>Diets to be followed.</p>
                <p id='diet-content'>${included}</p>
          </div>
          <div >
                <p id='diet-tl'>Diets to be Avoided.</p>
                <p id='diet-content'>${avoided}</p>
          </div>
           
          </div>
          <p id='Patient-Information'>
          Practices
        </p>
          <table>
        <thead>
          <tr>
            <th>Yoga</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
        ${Array.from(yogalist.values()).map((e, i) => {
        return `<tr key=${i}>
             <td>${e.name}</td>
             <td><a href=${e.link}>${e.link}</a></td> 
              </tr>`
      }).join(" ")}
        </tbody>
      </table>
      <p id='Patient-Information'>
      Lifestyle Changes
 </p>
       <div class='supplement'>
       <ul>
       ${lifestylechanges.split(",").map((e) => {
        return `
          <li>${e}</li>
        `
      }).join('')}
      </ul>

       </div>
            <p id='Patient-Information'>
             Supplements
        </p>
          <div class='supplements'>
         ${Array.from(selectedProducts.values()).map((e, i) => {
        return `<div  key=${e.product_id}>
      
        <p>${e.Product_name}<p>
        </div>`
      }).join(" ")
        }
          </div>
          <div class='doctor-footer'>
          <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/Doc_sign/WhatsApp_Image_2023-08-31_at_15.41.56-removebg-preview.png"  crossorigin="*" id="doc-sign"></img>
          <div>
          <p>${data.Doctors.Data.name}</p>
          <p>B.A.M.S,M.D.(Ayu.)</p>

<p>Ayurvedic physician</p>
<p>${day}/${month}/${Year}</p>
</div>

          <div>
         
        </div>
       
      </div>
        <p id='disclaimer'>*Please note that this prescription was generated by our Ayurvedic doctor after a teleconsultation.*<p>
      </div>
        </div>
      </div>
      </div>
    </body>
    </html>`

      const div = document.createElement('div');
      div.innerHTML = prescription;
      document.body.appendChild(div);
   
   
      const opt = {
        margin: [5, 5, 5, 5],
        filename: 'prescription.pdf',
        pagebreak: {avoid: 'tr'},
        // image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale:1, useCORS: true,allowTaint: true, },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };



      try {
        let pdf = await html2pdf().from(div).set(opt).outputPdf().then(function (pdf) {
          return btoa(pdf)
        });
        try {
          let res = await axios.post('https://diabapi.ayurai.in/api/data/Send_Prescription', {
            UID:id,
            Name: alldata?.Data?.firstname + " " + alldata?.Data?.lastname,
            age,
            email: alldata?.Data?.email,
            gender: alldata?.Data.gender,
            Medicaldetails,
            Practice:yogalist,
            lifestylechanges,
            Medicines: inputs,
            Diets: {
              Diets_included: included,
              Diets_avoided: avoided
            },
            Supplements: Array.from(selectedProducts.values()),
            file: pdf
          })
          if (res.status === 200) {
            Navigate(-1)
            toast.sucess(res.data, {
              position: "top-center",
              limit: 1,
              transition: Zoom,
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark"
            })
          }
        }
        catch (error) {
          console.log(error)
        }
      }
      catch (error) {
        console.error('Error generating PDF:', error);
      }

      document.body.removeChild(div);
    }
    else {
      toast.info("Please check the acknowledgment checkbox before proceeding", {
        position: "top-center",
        limit: 1,
        transition: Zoom,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  };

  const [selectedProducts, setSelectedProducts] = useState(new Map());
  const [yogalist, setyogalist] = useState(new Map())
  const [open, setOpen] = React.useState(false);
  const [Product, setProductlist] = useState([])
  const [Search, setsearch] = useState('')
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  let getData = (Data) => {
    try {
      const db = getDatabase();
      const Ref = ref(db, `/products`);
      onValue(Ref, async (snapshot) => {
        try {
          const data = await snapshot.val()
          setProductlist(Object.values(data))
        }
        catch (error) {
          console.log(error.response.data)
        }

      })

    }
    catch (error) {
      console.log(error)
    }
  }


  const handleProductClick = (Product) => {
    setSelectedProducts((prevSelectedProducts) => {
      const newSelectedProducts = new Map(prevSelectedProducts);
      if (newSelectedProducts.has(Product.product_id)) {
        newSelectedProducts.delete(Product.product_id);
      } else {
        newSelectedProducts.set(Product.product_id, { Product_id: Product.product_id, Product_name: Product.name,img:Product.imgurl })
      }
      return newSelectedProducts;
    });
  };

  const AddYogalist = (yogalists) => {
    setyogalist((prev) => {
      let yoga = JSON.parse(yogalists)
      const newlist = new Map(prev)
      if (newlist.has(yoga.name)) {
        newlist.delete(yoga.name)
      }
      else {
        newlist.set(yoga.name, yoga)
      }
      return newlist
    })
  }

  useEffect(() => {
    getData()
  }, [])


  return <>
    <SideBar />
    <div className="p-4 sm:ml-64">
      <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
        <div className='prescription_container'>
          <div className='Forms'>
            <h1>Prescription Form</h1>
            <div className='Form'>
              <TextField
                id="outlined-controlled"
                label="Patient's Name"
                value={alldata?.Data?.firstname + " " + alldata?.Data?.lastname}

              />
              <TextField
                id="outlined-controlled"
                label="Patient's Age"
                value={age}

              />
              <TextField
                id="outlined-controlled"
                label="Gender"
                value={alldata?.Data.gender}
              />
              <p id='Practise'>Medical details</p>
              <div className='medicine'>
                <TextField
                  className='medicine-input'
                  label="Complaints"
                  id="standard-size-small"
                  value={Medicaldetails.Complaints}
                  onChange={(e) => {
                    handleMedicaldetails("Complaints", e.target.value)
                  }}
                  variant="standard"
                />
                <TextField
                  className='medicine-input'
                  label="Examination /Lab findings"
                  id="standard-size-small"
                  value={Medicaldetails.lab}
                  onChange={(e) => {
                    handleMedicaldetails("lab", e.target.value)
                  }}
                  variant="standard"
                />
                <TextField
                  className='medicine-input'
                  label="Medical history"
                  id="standard-size-small"
                  value={Medicaldetails.history}
                  onChange={(e) => {
                    handleMedicaldetails("history", e.target.value)
                  }}
                  variant="standard"
                />
                <TextField
                  className='medicine-input'
                  label="Diagnosis"
                  id="standard-size-small"
                  value={Medicaldetails.diagnosis}
                  onChange={(e) => {
                    handleMedicaldetails("diagnosis", e.target.value)
                  }}
                  variant="standard"
                />
              </div>
              <p id='Practise'>Medicines</p>
              <>{inputs.map((input, index) => (
                <div className='medicine' key={index}>

                  <TextField
                    className='medicine-input'
                    label="Medicine Name"
                    id="standard-size-small"
                    value={input.medicine}
                    onChange={(event) => handleInputChange(index, event.target.value, "medicine")}
                    variant="standard"
                  />
                  <TextField
                    label="Quantity"
                    id="standard-size-small"
                    className='medicine-input'
                    // value={input.quantity}
                    onChange={(event) => handleInputChange(index, event.target.value, "quantity")}
                    variant="standard"
                  />
                  <TextField
                    className='medicine-input'
                    label="Dose"
                    id="standard-size-small"
                    onChange={(event) => handleInputChange(index, event.target.value, "dose")}
                    // value={input.dose}
                    variant="standard"
                  />
                  <TextField
                    label="Duration"
                    className='medicine-input'
                    id="standard-size-small"
                    // value={input.duration}
                    onChange={(event) => handleInputChange(index, event.target.value, "duration")}
                    variant="standard"
                  />
                  <TextField
                    label="Instruction"
                    className='medicine-input'
                    id="standard-size-small"
                    // value={input.instruction}
                    onChange={(event) => handleInputChange(index, event.target.value, "instruction")}
                    variant="standard"
                  />
                </div>))}
              </>
              <div className='add-more' onClick={handleAddField}>
                Add more <AddCircleOutlineIcon />
              </div>
              <p id='Practise'>Supplements</p>
              <div className='supplements'>
                {
                  Array.from(selectedProducts.values()).map((e) => {
                    return <p>{e.Product_name}</p>
                  })
                }
              </div>
              <div className='add-more' onClick={handleClickOpen}>
                Add more <LocalPharmacyIcon />
              </div>
              <p id='diet'>Diet</p>
              <div className='diet'>
                <TextField
                  id="outlined-multiline-static"
                  label="Include"
                  multiline
                  rows={6}
                  onChange={(e) => {
                    setinclude(e.target.value)
                  }}

                /> <TextField
                  id="outlined-multiline-static"
                  label="Avoid"
                  multiline
                  rows={6}
                  className='avoid'
                  onChange={(e) => {
                    setavoided(e.target.value)
                  }}
                />

              </div>
              <p id='Practise'>Practice</p>
              <div className='Practises'>
                <select className='Practise' onChange={(e) => {
                  AddYogalist(e.target.value)
                }}>
                  {
                    yoga.map((e, i) => {
                      return <>
                        <option value={JSON.stringify(e)} key={i}>{e.name}</option>
                      </>
                    })
                  }
                </select>

                <div className='Yogalist'>
                  {
                    Array.from(yogalist.values()).map((e) => {
                      return <p>{e.name}</p>
                    })
                  }
                </div>

              </div>
              <p id='Practise'>LifeStyle changes</p>
              <TextField
                id="outlined-multiline-static"
                label="Instructions"
                multiline
                rows={6}
                onChange={(e) => {
                  setlifestylechanges(e.target.value)
                }}

              />
            </div>

            <div className='formbtn'>
              <div className='checkbox'>
                <input type='checkbox' onChange={(e) => {
                  setchecked(e.target.checked)
                }}></input>
                <p>I, {data?.Doctors?.Data?.name}, acknowledge the submission of the prescription form for the patient named {alldata?.Data?.firstname + " " + alldata?.Data?.lastname} on {date.toString()}, and confirm the review of all relevant patient details and prescribed medications.</p>
              </div>
              <div onClick={genereatepdf} className='submitformbtn'>Submit</div>
            </div>
          </div>
        </div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Add Supplements"}
          </DialogTitle>
          <DialogContent className='dialog-content'>
            <div className='Search-bar'>
              <div className='search-box'>
                <SearchOutlined />
                <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />

              </div>
            </div>
            <div className='Product-list'>
              {
                Product.filter((s) => {
                  if (s.onsale === true) {
                    if (Search === '') {
                      return s
                    }
                    else if (s.name.toLowerCase().includes(Search.toLowerCase())) {
                      return s
                    }
                  }
                }).map((e, i) => {
                  return <div className='Product-card' style={selectedProducts.has(e.product_id) ? { backgroundColor: "#ADD8E6" } : { backgroundColor: "white" }} key={i} onClick={() => {
                    handleProductClick(e)
                  }}>
                    <img src={e.imgurl}></img>
                    <p>{e.name}</p>
                    {selectedProducts.has(e.product_id) ? <> <CheckCircleIcon style={{ color: "green" }} /></> : <></>}

                  </div>
                })
              }
            </div>

          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Next
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  </>
}

export default Prescription
