import React, { useEffect, useState } from 'react';
import AgoraUIKit from 'agora-react-uikit'
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const VideoCall = () => {

  const [videocall, setVideocall] = useState(false)
  const [token, setToken] = useState('')
  const [uid, setUid] = useState('')
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { channel } = useParams()
  const channelName = channel

  const props = {
    rtcProps: {
      appId: '71651055a8544ac794c3c70258b59162',
      channel,
      token,
      uid,
    },
    
    callbacks: {
     EndCall: async() =>{
      try{
        handleClickOpen()
        setVideocall(false)
      }
      catch(err){
        console.log(err)
      }
     }, 
    }
  }


  useEffect(() => {
    generateRtcToken()

  },[])



  const generateRtcToken = async () => {
    try {
      let res = await axios.post('https://diabapi.ayurai.in/api/data/rtcToken', {
        channelName,
      })

      if (res.status === 200) {
        setToken(res.data.key)
        setUid(res.data.uid)
        setVideocall(true)
      }

    }
    catch (error) {
      console.log(error?.response?.data)
    }
  }

  const styles = {
    container: { width: '100vw', height: '100vh', display: 'flex', flex: 1 },
  }

  return <>
    <div style={styles.container}>
      {videocall ? (
        <AgoraUIKit
          rtcProps={props.rtcProps}
          callbacks={props.callbacks}
          rtmProps={{ username:'user', displayUsername: true }} />
      ) : (
        <></>
      )}
    </div>
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0,  p: 2 }} id="customized-dialog-title">
          Finish Consultation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Do You want to end this call ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setVideocall(false)
            navigate(-1)
          }}>
            Yes
          </Button>
          <Button onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  </>
};
