import logo from './logo.svg';
import './App.css';
import Login from './Compontents/Login';
import Dashboard from './Compontents/Dashboard';
import Active_users from './Compontents/Active_user';
import DoctorsAppointment from './Compontents/DoctorsAppointment';
import Patient from './Compontents/Patient';
import { ToastContainer } from 'react-toastify';
import VideoPage from './Compontents/video';
import VoiceCall from './Compontents/VoiceCall';
import { VideoCall } from './Compontents/VideoCall';
import CaseSheet from './Compontents/CaseSheet';
import Prescription from './Compontents/Prescription';
import CaseStudy from './Compontents/CaseStudy';
import UserCaseStudy from './Compontents/UserCaseStudy';
import Chat from './Compontents/Chat';
import BookAppointment from './Compontents/BookAppointment';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
function App() {
  return <>
    <BrowserRouter>
      <ToastContainer />
      <Routes>
       
        <Route path='/' element={<Dashboard />} />
        <Route path='/Login' element={<Login />} />
        <Route path='/Activeusers' element={<Active_users />} />
        <Route path='/Prescription/:id' element={<Prescription />} />
        <Route path='/Patient/:id' element={<Patient />} />
        <Route path='/CaseSheet' element={<CaseSheet />} />
        <Route path='/CaseStudy' element={<CaseStudy />} />
        <Route path='/DoctorAppointment' element={<DoctorsAppointment />} />
        <Route path='/UserCaseStudy/:id' element={<UserCaseStudy></UserCaseStudy>} />
        <Route path='/VideoCall/:channel' element={<VideoCall></VideoCall>}/>
        <Route path='/VoiceCall/:channel' element={<VoiceCall/>}/>
        <Route path='/BookAppointment/:id' element={<BookAppointment/>}/>
        <Route path='/Chat/:id/:pid' element={<Chat />} />
      </Routes>
    </BrowserRouter>
  </>
}

export default App;
