import React, { useState, useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import PickCall from '../Compontents/animation_ln495gdl.json'
import Lottie from 'lottie-react';
import axios from 'axios';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Phone } from '@mui/icons-material';
const VoiceCall = () => {
  const [appId, setAppId] = useState('207f7f052b8e421f996cce30c739fba1');
  // const [channel, setChannel] = useState('APPOINTMENT_356674141');
  const [token, setToken] = useState('');
  const [uid, setUid] = useState('');
  const {channel}=useParams()
  const channelName=channel
  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [remoteAudioTrack, setRemoteAudioTrack] = useState(null);
  const [remoteUid, setRemoteUid] = useState(null);
  const [message, setMessage] = useState('');
  const [callDuration, setCallDuration] = useState(0);
  const [callStartTime, setCallStartTime] = useState(null);
  const [Joined,setJoined]=useState(false)
  const [isMuted, setIsMuted] = useState(false);
  const [isSoundOff, setIsSoundOff] = useState(false); 
  const agoraEngine = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp9' });
  let callDurationInterval;

  useEffect(()=>{
    generateRtcToken()
  },[Joined])

  useEffect(() => {
    if(token){
      setupAgoraListeners();
    }

    return () => {
      localAudioTrack && localAudioTrack.close();
      agoraEngine.leave();
      clearInterval(callDurationInterval);
    };
  }, [Joined]);

  useEffect(() => {
    if (callStartTime) {
          callDurationInterval = setInterval(() => {
        const currentTime = new Date();
        const durationInSeconds = Math.floor((currentTime - callStartTime) / 1000);
        setCallDuration(durationInSeconds);
      }, 1000);

      return () => clearInterval(callDurationInterval);
    }
  }, [callStartTime]);

  const generateRtcToken=async()=>{
    try{
     let res=await axios.post('https://diabapi.ayurai.in/api/data/rtcToken',{
      channelName,
     })
     if(res.status===200){
      console.log(res.data)
      setToken(res.data.key)
      setUid(res.data.uid)
     }
    }
    catch(error){
       console.log(error.response.data)
    }
  }

  const setupAgoraListeners = () => {
    agoraEngine.on('user-published', async (user, mediaType) => {
      await agoraEngine.subscribe(user, mediaType);
      console.log('subscribe success');
      console.log(user.getSessionStats)
      if (mediaType === 'audio') {
        setRemoteUid(user.uid);
        setRemoteAudioTrack(user.audioTrack);
        user.audioTrack.play();
        showMessage('Remote user connected: ' + user.uid);
      }

      agoraEngine.on('user-unpublished', (user) => {
        console.log(user.uid + ' has left the channel');
        showMessage('Remote user has left the channel');
      });
    });
  };

  const startCall = async () => {
    await agoraEngine.join(appId,channel, token, uid);
    showMessage('Joined channel: ' + channel);

    const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    setLocalAudioTrack(localAudioTrack);

    await agoraEngine.publish(localAudioTrack);
    console.log('Publish success!');

    setCallStartTime(new Date());
    setJoined(true)
  };


  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

const leaveCall = () => {
  localAudioTrack && localAudioTrack.close();
  agoraEngine.leave();
  console.log('You left the channel');
  setJoined(false)
  setLocalAudioTrack(0);
  setRemoteAudioTrack(null);
  setRemoteUid(null);
  setLocalAudioTrack(null);
  setRemoteAudioTrack(null);
  setRemoteUid(null);
  setCallDuration(0);
  setCallStartTime(null);
};
const toggleMute = (mute) => {
  if (localAudioTrack) {
    localAudioTrack.setEnabled(mute);
    setIsMuted(!isMuted);
  }
};

const toggleSoundOff = (volume) => {
  if (remoteAudioTrack) {
    remoteAudioTrack.setVolume(volume);
    setIsSoundOff(!isSoundOff);
  }
};

const showMessage = (text) => {
  setMessage(text);
};

return <>
<div className='VoiceCall-page'>
   
  <div className='VoiceCall-compontents'>
     <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/harinee.jpg'></img>
     <p>Dr. Harinee S</p>
     <p>{Joined && formatTime(callDuration)}</p>
  </div>

  <div className='VoiceCall-controlls'>
    {
     Joined ? <div className='VoiceCall-controlls-call-settings mb-5'>
      <IconButton onClick={()=>{isMuted?toggleMute(true):toggleMute(false)}}>{isMuted?<MicOffIcon></MicOffIcon>:<MicIcon></MicIcon>}</IconButton>

      <IconButton style={{backgroundColor:'red',color:"white"}} className='hangoutcall' onClick={leaveCall}><Phone></Phone></IconButton>
      
      <IconButton onClick={()=>{isSoundOff?toggleSoundOff(100):toggleSoundOff(100)}}>{isSoundOff?<VolumeOffIcon></VolumeOffIcon>:<VolumeUpIcon></VolumeUpIcon>}</IconButton>
     </div>:<div onClick={startCall} style={{cursor:"pointer"}}><Lottie animationData={PickCall}></Lottie></div>
    }

  </div>
</div>
</>
  // <div>
  //   <button onClick={startCall}>Join</button>
  //   <button onClick={leaveCall}>Leave</button>
  //   <p>Call Duration: {formatTime(callDuration)}</p>
  //   <div id="message">{message}</div>
  // </div>



}

export default VoiceCall;
