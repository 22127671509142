import React, { useState, useContext, useEffect } from 'react'
import SideBar from './SideBar'
import Diversity1Icon from '@mui/icons-material/Diversity1';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from './setAuthToken';
import { CChart, CChartBar } from '@coreui/react-chartjs'
import axios from 'axios';
import { DateRangePicker } from 'react-date-range'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { contextAPI } from './Context';

import Skeleton from '@mui/material/Skeleton';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DoctorsAppointment from './DoctorsAppointment';
function Dashboard() {
    let [months, setmonth] = useState([])
    let [filter, setFilter] = useState(false)
    let [Startdate, setStartDate] = useState('')
    let [endDate, setEndDate] = useState('')

    let Handlefilter = () => {
        setFilter(!filter)
    }
    const handleSelect = (date) => {
        console.log(date.selection.endDate)
        setStartDate(date.selection.startDate)
        setEndDate(date.selection.endDate)
    };

    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    let navigate = useNavigate()

    let data = useContext(contextAPI)


    useEffect(() => {
        if (!sessionStorage.getItem('AccessToken')) {
            navigate('/Login')
        }
        else {
           data.getDoctors()
           data.getDoctorsAppointment()
           data.getUsers()
        }

    }, [sessionStorage.getItem('AccessToken')])


    return <>
        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
                <div className='Main-contents'>
                    <div className='Cards'>
                        <div className='card'>
                            <div className='Icons' style={{ backgroundColor: "#90EE90" }}>
                                <PendingActionsIcon style={{ color: "green" }} />
                            </div>

                            <div className='card-details' id='order'>
                                <p>{data.DoctorAppointment.filter((e)=>{
                                    if(e.Data.appointment_status === 'upcoming'){
                                        return e
                                    }
                                }).map((e)=>{return e}).length}</p>
                                <p>Pending</p>
                            </div>
                        </div>
                        <div className='card' >
                            <div className='Icons' style={{ backgroundColor: "#FFCCCB" }}>
                                <EventBusyIcon style={{color: "red"}} />
                            </div>
                            <div className='card-details'>
                            <p>{data.DoctorAppointment.filter((e)=>{
                                    if(e.Data.appointment_status === 'cancelled' || e.Data.appointment_status === 'cancelled_time'){
                                        return e
                                    }
                                }).map((e)=>{return e}).length}</p>
                                <p>Cancelled</p>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='Icons' style={{ backgroundColor: "#90EE90" }}>
                                <CalendarMonthIcon style={{ color: "green" }} />
                            </div>
                            <div className='card-details'>
                                <p>{data.DoctorAppointment.length > 0 && data.DoctorAppointment.length}</p>
                                <p>Appointments</p>
                            </div>
                        </div>

                        <div className='card' >
                            <div className='Icons' style={{ backgroundColor: "#89CFF0" }}>
                                <EventAvailableIcon style={{ color: "blue" }} />
                            </div>
                            <div className='card-details'>
                            <p>{data.DoctorAppointment.filter((e)=>{
                                    if(e.Data.appointment_status === 'completed'){
                                        return e
                                    }
                                }).map((e)=>{return e}).length}</p>
                               
                                <p>Completed</p>
                            </div>

                        </div>
                    </div>
                   
                </div>
                <DoctorsAppointment style={{width:'10vw'}}/>
            </div>
        </div>
    </>
}

export default Dashboard