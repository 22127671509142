import React from "react";
import SideBar from "./SideBar";
import { useContext, useState, useEffect, useRef } from "react";
import { contextAPI } from './Context';
import { useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { CChart, CChartBar } from '@coreui/react-chartjs'
import ChatIcon from '@mui/icons-material/Chat';
import HeightIcon from '@mui/icons-material/Height';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import SummarizeIcon from '@mui/icons-material/Summarize';
import male from './patient.png'
import female from './patient_1430454.png'
import PostAddIcon from '@mui/icons-material/PostAdd';
import SendIcon from '@mui/icons-material/Send';
import { setAuthToken } from './setAuthToken';
import CloseIcon from '@mui/icons-material/Close';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { io } from "socket.io-client";
import axios from 'axios'
import AgoraUIKit from 'agora-react-uikit';
import { Link } from "react-router-dom";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { v4 as uuidv4 } from "uuid";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Button, IconButton } from "@mui/material";
const actions = [
    { icon: <AddPhotoAlternateIcon />, name: 'image' },
    { icon: <VideoFileIcon />, name: 'video' },
    { icon: <AudioFileIcon />, name: 'Audio' },
    { icon: < DocumentScannerIcon />, name: 'Documents' },
];
function Patient() {
    let data = useContext(contextAPI)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    
    const [count, setCount] = useState(0)
    const socket = useRef();
    let [prakriti_data, setPrakriti] = useState([])
    let [vikriti_data, setvikriti] = useState([])
    let navigate = useNavigate()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    let { id } = useParams()

    let patientdetails = data.DoctorAppointment.filter((e) => {
        if (e.Data.appointmentID === id) {
            return e
        }
    }).map((e) => { return e })

    let alldatadetails = patientdetails[0]

    let patient = data.users.filter((e) => {
        if (alldatadetails.UID === e.UID) {
            return e
        }
    }).map((e) => { return e })

    let alldata = patient[0]

    var dob = new Date(`${alldata?.Data?.dob.split('/')[1].toString()}/${alldata?.Data?.dob.split('/')[0].toString()}/${alldata?.Data?.dob.split('/')[2].toString()}`);

    var month_diff = Date.now() - dob.getTime();

    var age_dt = new Date(month_diff);

    var year = age_dt.getUTCFullYear();


    var age = Math.abs(year - 1970);

    let getPrakriti = async () => {
        try {

            let res = await axios.post('https://diabapi.ayurai.in/api/data/getPrakritiforDoctors', {
                UID: alldata.UID
            })
            if (res.status === 200) {
                setPrakriti(res.data.Data)
            }
        }
        catch (error) {
            console.log(error)

        }

    }

    let getvikriti = async () => {
        try {

            let res = await axios.post('https://diabapi.ayurai.in/api/data/getVikritiforDoctors', {
                UID: alldata.UID
            })

            if (res.status === 200) {
                setvikriti(res.data.Data)
            }
        }
        catch (error) {
            console.log(error.response)
        }
    }

    useEffect(() => {
        getPrakriti()
    }, [])

    useEffect(() => {
        getvikriti()
    }, [])

    const completeAppointment = async () => {
        try {
            let Token = sessionStorage.getItem('AccessToken')
            setAuthToken(Token)
            let AppointmentStatus = await axios.post('https://diabapi.ayurai.in/api/data/updateOneAppointment', {
                key: "appointment_status",
                value: "completed",
                appointmentID: id
            })

            if (AppointmentStatus.status === 200) {
                navigate(-1)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const confirmAppointment = async () => {
        try {
            let Token = sessionStorage.getItem('AccessToken')
            setAuthToken(Token)
            let AppointmentStatus = await axios.post('https://diabapi.ayurai.in/api/data/updateOneAppointment', {
                key: "doctor_status",
                value: "confirmed",
                appointmentID: id
            })

            if (AppointmentStatus.status === 200) {
                navigate(-1)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const StartConsultation=async()=>{
        try {
            let Token = sessionStorage.getItem('AccessToken')
            setAuthToken(Token)
            let AppointmentStatus = await axios.post('https://diabapi.ayurai.in/api/data/updateOneAppointment', {
                key: "consultation_status",
                value: "start",
                appointmentID: id
            })

            if (AppointmentStatus.status === 200) {
                if(alldatadetails?.Data?.consultation_type === 'video'){
                    navigate(`/VideoCall/${id}`)
                }
                else{
                    navigate(`/VoiceCall/${id}`)
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    return <>
        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
                <div className="Patient-section">
                    <div className="patient-details">
                        {alldata?.Data?.gender === 'Male' ? <>
                            <img src={male} className="patient-img"></img>
                        </> : <><img src={female} className="patient-img"></img></>
                        }
                        <p id='patient-name' >{alldata?.Data?.firstname + " " + alldata?.Data?.lastname}</p>
                        <p>{alldata?.Data?.email}</p>
                        <p id='age'>{age} <span id='years'>years</span></p>
                        <div className='fitness'>
                            <div>
                                <p className="patient-fitness">
                                    Weight <FitnessCenterIcon />
                                </p>
                                <p className="patient-fitness-details">{alldata?.Data?.weight}</p>
                            </div>
                            <div>
                                <p className="patient-fitness">
                                    Height <HeightIcon />
                                </p>
                                <p className="patient-fitness-details">{alldata?.Data?.height}</p>
                            </div>
                        </div>
                    </div>
                    <div className="patient-more-details">
                        <div className="paitent-contact-details">
                            <div>
                                <p id='patient-contact-title'>Appointment ID</p>
                                <p id='patient-contact-content'>{alldatadetails?.Data?.appointmentID}</p>
                            </div>
                            <div>
                                <p id='patient-contact-title'>Phone Number</p>
                                <p id='patient-contact-content'>{alldatadetails?.Data?.phone}</p>
                            </div>

                            {alldatadetails?.Data?.doctor_status === 'confirmed' && alldatadetails.Data?.appointment_status != 'completed' ?
                                <div>
                                    <p id='patient-contact-title'>Consultation</p>
                                    {alldatadetails?.Data?.consultation_type === 'video' ?
                                        <IconButton onClick={() => {
                                            StartConsultation()
                                        }}><VideoChatIcon /></IconButton> : <IconButton onClick={() => {
                                            StartConsultation()
                                        }}><PermPhoneMsgIcon /></IconButton>}
                                    <IconButton><SummarizeIcon /></IconButton>
                                </div> : <></>}

                            <div>
                                <p id='patient-contact-title'>Patient ID</p>
                                <p id='patient-contact-content'>{alldatadetails?.Data?.patient_id}</p>
                            </div>
                            <div className="Upload-reports" onClick={() => { navigate(`/Prescription/${id}`) }}>
                                <PostAddIcon />&nbsp;&nbsp;Upload prescriptions
                            </div>
                            {alldatadetails?.Prescription
                                ?.Prescription_link &&
                                <a className="Upload-reports" href={alldatadetails?.Prescription
                                    ?.Prescription_link} download>
                                    <GetAppRoundedIcon />&nbsp;&nbsp;Download prescription
                                </a>}
                        </div>
                        <div className="consultation">
                            <div className="consultation-data">
                                <p id='datetype'>{alldatadetails?.Data?.appointment_date}</p>
                                <p id='time'>{alldatadetails?.Data?.appointment_time}</p>
                            </div>
                            <div className="consultation-data">
                                <p id='consultation-mode'>{'Consultation Mode'}</p>
                                <p id='consultation-mode-type'>{alldatadetails?.Data?.consultation_type}</p>
                            </div> <div className="consultation-data">
                                <p id='consultation-mode'>{'Consulation Charges'}</p>
                                <p id='consultation-mode-type'>₹ {alldatadetails?.Data?.appointment_pay}</p>
                            </div>
                        </div>
                        <div className="action-buttons">
                            {alldatadetails?.Data?.doctor_status === 'pending' ? <>
                                <Button variant="outlined" onClick={handleOpen2} >
                                    Confirm
                                </Button>

                            </> : alldatadetails?.Data?.doctor_status === 'confirmed' && alldatadetails.Data?.appointment_status != 'completed'
                            && alldatadetails.Data?.appointment_status != 'rescheduled' ?
                                <>
                                    <Button variant="contained" onClick={()=>{
                                        navigate(`/Bookappointment/${id}`)
                                    }}>
                                        Reschedule
                                    </Button>
                                    <Button variant="outlined" onClick={handleOpen}>
                                        Complete
                                    </Button>
                                </>
                                : <></>
                            }
                        </div>
                    </div>
                </div>
                <div className="paitent-results">
                    <h1>
                        Results
                    </h1>
                    <div className="charts">
                        <div className='chart'>
                            {vikriti_data.VIKRITIVATAPULSE == 0 && vikriti_data.VIKRITIPITTAPULSE == 0 && vikriti_data.VIKRITIKAPHAPULSE == 0 ?
                                <CChart
                                    type="bar"
                                    data={{
                                        labels: ['Vata', 'Pitta', 'Kapha',],
                                        datasets: [
                                            {
                                                label: "Vata",
                                                backgroundColor: ['rgb(100,181,246)', 'rgb(129,199,132)', 'rgb(229,115,115)'],
                                                data: [((vikriti_data?.vatascore / vikriti_data?.count) * 100 * 0.7), ((vikriti_data.pittascore / vikriti_data.count) * 100 * 0.7),

                                                ((vikriti_data.kaphascore / vikriti_data.count) * 100 * 0.7)],
                                                formatter: (value) => `${value}%`,
                                            },
                                        ],

                                    }}


                                /> : vikriti_data?.count === 0 ?
                                    <CChart
                                        type="bar"
                                        data={{
                                            labels: ['Vata', 'Pitta', 'Kapha',],
                                            datasets: [
                                                {
                                                    label: "Vata",
                                                    backgroundColor: ['rgb(100,181,246)', 'rgb(129,199,132)', 'rgb(229,115,115)'],
                                                    data: [((
                                                        vikriti_data?.
                                                            VIKRITIVATAPULSE) / (vikriti_data?.
                                                                VIKRITIVATAPULSE + vikriti_data?.
                                                                    VIKRITIPITTAPULSE + vikriti_data?.
                                                                    VIKRITIKAPHAPULSE) * 100 * 0.3), ((
                                                                        vikriti_data?.
                                                                            VIKRITIPITTAPULSE) / (vikriti_data?.
                                                                                VIKRITIVATAPULSE + vikriti_data?.
                                                                                    VIKRITIPITTAPULSE + vikriti_data?.
                                                                                    VIKRITIKAPHAPULSE) * 100 * 0.3),

                                                    ((
                                                        vikriti_data?.
                                                            VIKRITIKAPHAPULSE) / (vikriti_data?.
                                                                VIKRITIVATAPULSE + vikriti_data?.
                                                                    VIKRITIPITTAPULSE + vikriti_data?.
                                                                    VIKRITIKAPHAPULSE) * 100 * 0.3)],
                                                    formatter: (value) => `${value}%`,
                                                },
                                            ],

                                        }}


                                    /> : <CChart
                                        type="bar"
                                        data={{
                                            labels: ['Vata', 'Pitta', 'Kapha',],
                                            datasets: [
                                                {
                                                    label: "Vata",
                                                    backgroundColor: ['rgb(100,181,246)', 'rgb(129,199,132)', 'rgb(229,115,115)'],
                                                    data: [((vikriti_data?.vatascore / vikriti_data?.count) * 100 * 0.7) + ((
                                                        vikriti_data?.
                                                            VIKRITIVATAPULSE) / (vikriti_data?.
                                                                VIKRITIVATAPULSE + vikriti_data?.
                                                                    VIKRITIPITTAPULSE + vikriti_data?.
                                                                    VIKRITIKAPHAPULSE) * 100 * 0.3), ((vikriti_data.pittascore / vikriti_data.count) * 100 * 0.7) + ((
                                                                        vikriti_data?.
                                                                            VIKRITIPITTAPULSE) / (vikriti_data?.
                                                                                VIKRITIVATAPULSE + vikriti_data?.
                                                                                    VIKRITIPITTAPULSE + vikriti_data?.
                                                                                    VIKRITIKAPHAPULSE) * 100 * 0.3),

                                                    ((vikriti_data.kaphascore / vikriti_data.count) * 100 * 0.7) + ((
                                                        vikriti_data?.
                                                            VIKRITIKAPHAPULSE) / (vikriti_data?.
                                                                VIKRITIVATAPULSE + vikriti_data?.
                                                                    VIKRITIPITTAPULSE + vikriti_data?.
                                                                    VIKRITIKAPHAPULSE) * 100 * 0.3)],
                                                    formatter: (value) => `${value}%`,
                                                },
                                            ],

                                        }}


                                    />
                            }
                            <p>Vikriti</p>
                        </div>
                        <div className='chart'>
                            <CChart

                                type="pie"
                                data={{
                                    labels: ['Vata', 'Pitta', 'Kapha',],
                                    datasets: [
                                        {
                                            backgroundColor: ['rgb(100,181,246)', 'rgb(129,199,132)', 'rgb(229,115,115)'],
                                            data: [prakriti_data.vata, prakriti_data.pitta, prakriti_data.kapha],
                                        },
                                    ],
                                }}
                            />
                            <p>Prakriti</p>
                        </div>
                    </div>
                </div>
                {
                    //                 ChatScreen &&
                    //                 <div className="Chat">
                    //                     <div className="Chat-section">
                    //                         <div className="chat-personal-details"><Avatar></Avatar>
                    //                             <div><p>{alldata?.Data?.firstname + " " + alldata?.Data?.lastname}</p>
                    //                                 <p>Offline</p></div>
                    //                         </div>
                    //                         <CloseIcon onClick={handleChat} />
                    //                     </div>

                    //                     <div className="Chat-container"  ref={scrollRef}  >
                    //                      {
                    //                     Messages.map((e,i)=>{
                    //                         return (
                    //                             <div
                    //                             ref={scrollRef} className={e.fromSelf===false ?  "message":"message own"} key={uuidv4()}
                    //                           >

                    //             <span>{e.message}</span>
                    //         </div>
                    //                         )
                    //                     })
                    //                      }
                    //                     </div>
                    //                     {/* <div className="chat-footer">
                    //             <textarea ref={textAreaRef} placeholder="Message..." value={msg} type='text' onChange={(e) => {setmsg(e.target.value)}} row="1"  />&nbsp;
                    //             {msg.length>0?<>
                    //     <div className='sendbtn' type="submit" 
                    //     ><SendIcon /></div>

                    //    </>:<> <SpeedDial
                    //     ariaLabel="SpeedDial controlled open example"
                    //     sx={{ position: 'absolute', bottom: 16, right: 16 }}
                    //     icon={<SpeedDialIcon />}
                    //     onClose={handleClose}
                    //     onOpen={handleOpen}
                    //     open={open}
                    //   >
                    //     {actions.map((action) => (
                    //       <SpeedDialAction
                    //         key={action.name}
                    //         icon={action.icon}
                    //         tooltipTitle={action.name}
                    //         onClick={handleClose}
                    //       />
                    //     ))}
                    //   </SpeedDial></>}
                    //             </div> */}
                    //                     <div class="input-container">
                    //                         <textarea ref={textAreaRef} placeholder="Message..." value={msg} type='text' onChange={(e) => { setmsg(e.target.value) }} row="1" />
                    //                         <div class="send-button-container">
                    //                             {msg.length > 0 ? <> <button id="send-button" onClick={(e)=>{
                    //                                 sendchat(e)
                    //                             }}>
                    //                                 <SendIcon /></button></> : <>
                    //                                 <SpeedDial
                    //                                     ariaLabel="SpeedDial controlled open example"
                    //                                     sx={{ position: 'absolute', bottom: 8, right: 16, }}
                    //                                     icon={<SpeedDialIcon />}
                    //                                     onClose={handleClose}
                    //                                     onOpen={handleOpen}
                    //                                     open={open}
                    //                                 >
                    //                                     {actions.map((action) => (
                    //                                         <SpeedDialAction
                    //                                             key={action.name}
                    //                                             icon={action.icon}
                    //                                             tooltipTitle={action.name}
                    //                                             onClick={handleClose}
                    //                                         />
                    //                                     ))}   
                    //                                 </SpeedDial></>
                    //                             }
                    //                         </div>
                    //                     </div>
                    //                 </div>
                }
                <div className="chat-button">
                    <IconButton style={{ backgroundColor: "lightcyan", padding: "1rem" }} onClick={()=>{
                        navigate(`/Chat/${id}/${alldatadetails?.UID}`)
                    }}>
                        <ChatIcon />
                    </IconButton>
                </div>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Are you sure?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You want to complete this Appointment
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            No
                        </Button>
                        <Button onClick={completeAppointment} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullScreen={fullScreen}
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Are you sure?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You want to confirm this Appointment
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose2}>
                            No
                        </Button>
                        <Button onClick={confirmAppointment} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    </>

}
export default Patient