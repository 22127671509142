import React from 'react'
import SideBar from './SideBar'
import Table from '@mui/material/Table';
import { useState, useContext } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { SearchOutlined } from '@mui/icons-material'
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { contextAPI } from './Context';
function DoctorsAppointment(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [Search, setsearch] = useState('')
    const [about, setAbout] = useState('')
    const [open, setOpen] = useState(false);
    const data =useContext(contextAPI)
    const handleClickOpen = (id) => {
        setAbout(id)
        // alert(id)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }
    let Navigate = useNavigate()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return <>
      
                <h1 className='Order_title'>Appointments</h1>
                <div className='Search-bar'>
                    <div className='search-box'>
                        <SearchOutlined />
                        <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />

                    </div>
                </div>
                <TableContainer sx={{ maxHeight: 440 }} className='Table mt-5' >
                    <Table stickyHeader aria-label="sticky table" >
                        <TableHead>
                            <TableRow>

                                <TableCell className='Table-header'
                                    style={{ minWidth: "100px" }}

                                >
                                   Patient Name
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                     Doctor Name
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    Doctor Speciality
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    Appointment Status
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    Appointment Date
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    More info
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.DoctorAppointment
                            .sort((a,b)=>{
                                let A=a.Data.appointment_status === 'upcoming'
                                let B=b.Data.appointment_status === 'upcoming'
                                if(A&&!B){
                                    return -1
                                }
                                else if(!A&&B){
                                    return 1
                                }
                                else{
                                    return 0
                                }
                            })
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((s) => {
                                    if (Search === "") {
                                        return s;
                                    }
                                    else if (s.Data.patient_name.toLowerCase().includes(Search.toLowerCase())||s.Data.doctor_name.toLowerCase().includes(Search.toLowerCase())) {
                                        return s;
                                    }
                                })
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>


                                            <TableCell >
                                                {row.Data.patient_name}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.Data.doctor_name}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.Data.doctor_speciality}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                
                                            <div style={row.Data.appointment_status === 'cancelled'||row.Data.appointment_status ==='cancelled_time' ? { color: "red", padding: '10px', backgroundColor: "#FFCCCB", borderRadius: '8px' } : row.Data.appointment_status === 'pending' ? { color: "yellow", backgroundColor: '#FFFFE0', borderRadius: '8px', padding: '10px' } : row.Data.appointment_status === 'completed' ? { color: 'blue',backgroundColor: "#89CFF0", borderRadius: '8px', padding: '10px' } : {
                          color: "green", padding: '10px', backgroundColor: "#FFFFE0", borderRadius: '8px'
                        }}>{row.Data.appointment_status}</div>
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.Data.appointment_date}
                                            </TableCell>
                                           
                                            <TableCell align={"center"}>
                                                <Button variant="outlined" onClick={() =>Navigate(`/Patient/${row.Data.appointmentID}`)}>
                                                    view
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <TableFooter align="right">
                        <TablePagination

                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={data.DoctorAppointment.length}

                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableFooter>
                </TableContainer>
                

    
    </>
}
export default DoctorsAppointment