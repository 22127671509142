import React from 'react'
import SideBar from './SideBar'
import { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material'
import { getDatabase, ref, onValue, } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import { contextAPI } from './Context';
import axios from 'axios';
import { db } from './firebase'
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Slider, { SliderThumb } from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { Fade, Slide } from "react-awesome-reveal";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SearchOutlined } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Check from '@mui/icons-material/Check';
import { useContext } from "react";
import { useParams } from 'react-router-dom';
import logo from './ayurailogofull.png'
import { toast, Zoom } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)'
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: 'white',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage: 'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage: 'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)'
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <p>1</p>,
        2: <p>2</p>,
        3: <p>3</p>,
        4: <p>4</p>,
        5: <p>5</p>,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
      @default false
     
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,

    icon: PropTypes.node,
};
const PrettoSlider = styled(Slider)({
    color: '#blue',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: 'blue',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&::before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const PrettoSlider1 = styled(Slider)({
    color: 'red',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: 'red',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&::before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const PrettoSlider2 = styled(Slider)({
    color: '#52af77',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&::before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});
function CaseSheet() {
    const steps = ['Patient Details', 'Disease History', 'Personal history', 'Clinical Assessment', 'Diagnosis'];
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const [PatientDetails, setPatientDetails] = useState(
        {
            Name: "", Age: 0, Height: "", Weight: "",
            Gender: "", Maritalstatus: "", Occupation: "",
            PresentResidence: "", BirthPlace: ""
        }
    )

    const [SleepDetails, setSleepDetails] = useState({
        QualityOFSleep: "",
        Diwaswapna: "No",
        Ratrijagarana: "No"
    })
    
    const [Bowel,setBowel]=useState({
       BowelMovements:"",
       Koshta:""
    })

    const [OccupationalHistory, setOccupationalHistory] = useState({
        Physical: "",
        Mental: ""
    })

    const [Micturition, setMicturition] = useState('')

    const [DietType, setDietType] = useState('')
    const [Agni, setAgni] = useState('')
    const [Surgeries,setSurgeries]=useState('')

    const HandlePatientDetails = (key, value) => {
        setPatientDetails(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const HandleGynecological = (key, value) => {
        setGynecological(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const HandleBowel = (key, value) => {
        setBowel(prev => ({
            ...prev,
            [key]: value
        }))
    }
    const HandlePrakriti = (key, value) => {
        setPrakritiDetails(prev => ({
            ...prev,
            [key]: value
        }))
    }
    const HandleDosham = (key, value) => {
        setDosham(prev => ({
            ...prev,
            [key]: value
        }))
    }
    const HandleOccupationalHistory = (key, value) => {
        setOccupationalHistory(prev => ({
            ...prev,
            [key]: value
        }))
    }
    
    const HandleSleepDetails = (key, value) => {
        setSleepDetails(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const HandleObstectricalHistory = (key, value) => {
        setObstectricalHistory(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const Navigate = useNavigate()


    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
     const [complaints, setComplaints] = useState({
        polyuria: { selected: '', duration: '',complaintName:"Prabhuta Mutrata (Polyuria)" },
        turbidUrine: { selected: '', duration: '',complaintName:"Aavil Mutrata (Turbid urine)" },
        polyphagia: { selected: '', duration: '' ,complaintName:"Kshudhadhikya (Polyphagia)"},
       polydipsia: { selected: '', duration: '' ,complaintName:"Trishnadhikya (Polydipsia)"},
        throatDryness: { selected: '', duration: '',complaintName:"Gal-Talushosha (Throat dryness)" },
        palmSoleBurning: { selected: '', duration: '',complaintName:"Kara-Padatala Daha (Burning sensation in palm & soles)" },
        legCramps: { selected: '', duration: '',complaintName:"Pindikodwestana (Leg cramps)" },
    })
    const [Othercomplaints, setOtherComplaints] = useState({
        sweetTasteInMouth: { selected: '', duration: '',complaintName:"Asya-Madhurya (Sweet taste in mouth)"  },
        teethSensation: { selected: '', duration: '' ,complaintName:"Danteshu-Malotpatti (Teeth sensation/Pyorrhoea)" },
        sweatingWithSmell: { selected: '', duration: '',complaintName:"Swedaadhikya-Anga-Gandha (Sweating with smell)"  },
        blurringVision: { selected: '', duration: '',complaintName:"Netra-Updeha (Blurring vision)"  },
        bodyOleosity: { selected: '', duration: '' ,complaintName:"Deha-Chikkanata (Body Oleosity)" }
    });

    const [diseases, setDiseases] = useState({
        hypertension: { selected: '', duration: '',complaintName:"hypertension"  },
        ihdCad: { selected: '', duration: '' ,complaintName:"ihdCad" },
        bronchial: { selected: '', duration: '' ,complaintName:"bronchial" },
        tuberculosis: { selected: '', duration: '',complaintName:"tuberculosis"  },
        allergy: { selected: '', duration: '',complaintName:"allergy"  },
        gastrointestinalDisease: { selected: '', duration: '' ,complaintName:"gastrointestinalDisease" },
        jaundice: { selected: '', duration: '' ,complaintName:"jaundice" },
        raOa: { selected: '', duration: '',complaintName:"raOa"  },
        skinDisease: { selected: '', duration: '' ,complaintName:"skinDisease" },
        thyroidDisease: { selected: '', duration: '' ,complaintName:"thyroidDisease" },
        anaemia: { selected: '', duration: '',complaintName:"anaemia"  },
        piles: { selected: '', duration: '',complaintName:"piles"  },
        headacheVomiting: { selected: '', duration: '',complaintName:"headacheVomiting"  },
        vertigo: { selected: '', duration: '' ,complaintName:"vertigo" },
        seizures: { selected: '', duration: '',complaintName:"seizures"  },
        urinaryCalculi: { selected: '', duration: '',complaintName:"urinaryCalculi"  }
    });

    const SubmitCaseSheet=async()=>{
        try{
          let res=await axios.post('https://diabapi.ayurai.in/api/data/uploadCaseSheet',{
            PatientDetails,
            DiseaseHistory:{
                complaints,
                Othercomplaints,
                diseases,
                HistoryofPastillness
            },
            PersonalHistory:{
                Diet:{
                  DietType,
                  Agni
                },
                Habits:foodHabits,
                Addictions,
                PhysicalActivity:Exercise,
                Sleep:SleepDetails,
                OccupationalHistory,
                Micturition,
                Bowel,
                Gynecological,
                ObstectricalHistory,
                Surgeries
            },
            ClinicalAssessment:{
                PrakritiDetails:{...PrakritiDetails,Vata:PrakritiVata,Pitta:PrakritiPitta,Kapha:PrakritiKapha},
                Dosham:{...Dosham,Vata:DoshamVata,Pitta:DoshamPitta,Kapha:DoshamKapha}
            }, 
            Diagnois:{
                AyurvedaDiagnois,
                ModernDiagnois
            }
            

          })
        }
        catch(error){
        console.log(error)
        }
    }
    
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        if (activeStep === 4) {
            setActiveStep(4)
            SubmitCaseSheet()

            setTimeout(() => {
                setActiveStep(0)
            }, 2000)
        }
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [Exercise, setExercise] = useState('')
    const [HistoryofPastillness, setPastillness] = useState('')

    const [ObstectricalHistory, setObstectricalHistory] = useState({
        NoOfDelivery: 0,
        TypeofDelivery: "",
        Hysterectomy: "",
        AnyOther: "none"
    })

    const [PrakritiDetails,setPrakritiDetails]=useState({
        Dominant:'',
        Assoicated:'',
    })

    const [PrakritiVata,setPrakritiVata]=useState(10)
    const [PrakritiPitta,setPrakritiPitta]=useState(10)
    const [PrakritiKapha,setPrakritiKapha]=useState(10)

    const [DoshamVata,setDoshamVata]=useState(10)
    const [DoshamPitta,setDoshamPitta]=useState(10)
    const [DoshamKapha,setDoshamKapha]=useState(10)

    const [Dosham,setDosham]=useState({
        Decreased:'',
        Increased:'',
    })
    
    const [AyurvedaDiagnois,setAyurvedaDiagnois]=useState('')
    const [ModernDiagnois,setModernDiagnois]=useState('')

    const [foodHabits, setFoodHabits] = useState({
        tea: { selected: '', frequency: '',habitName:'Tea'},
        coffee: { selected: '', frequency: '',habitName:"Coffee" },
        softDrinks: { selected: '', frequency: '',habitName:"SoftDrinks" },
    });

    const [Gynecological, setGynecological] = useState({
        MenstrualCycle: "",
        MenstrualFlow: "",
        Menopause: ""
    })

    const [Addictions, setAddictions] = useState({
        Tobacco: { selected: '', frequency: '' ,AddictionName:"Tobacco"},
        Smoking: { selected: '', frequency: '',AddictionName:"Smoking" },
        Alcohol: { selected: '', frequency: '',AddictionName:"Alcohol" },
    });

    const handleRadioChange4 = (habit, value) => {
        setFoodHabits({
            ...foodHabits,
            [habit]: { ...foodHabits[habit], selected: value },
        });
    };

    const handleFrequencyChange4 = (habit, value) => {
        setFoodHabits({
            ...foodHabits,
            [habit]: { ...foodHabits[habit], frequency: value },
        });
    };

    const handleRadioChange5 = (addiction, value) => {
        setAddictions({
            ...Addictions,
            [addiction]: { ...Addictions[addiction], selected: value },
        });
    };

    const handleFrequencyChange5 = (addiction, value) => {
        setAddictions({
            ...Addictions,
            [addiction]: { ...Addictions[addiction], frequency: value },
        });
    };

    

    const OthercomplaintLabels = {
        sweetTasteInMouth: "Asya-Madhurya (Sweet taste in mouth)",
        teethSensation: "Danteshu-Malotpatti (Teeth sensation/Pyorrhoea)",
        sweatingWithSmell: "Swedaadhikya-Anga-Gandha (Sweating with smell)",
        blurringVision: "Netra-Updeha (Blurring vision)",
        bodyOleosity: "Deha-Chikkanata (Body Oleosity)"
    };

    const complaintsLabels = {
        polyuria: "Prabhuta Mutrata (Polyuria)",
        turbidUrine: "Aavil Mutrata (Turbid urine)",
        polyphagia: "Kshudhadhikya (Polyphagia)",
        polydipsia: "Trishnadhikya (Polydipsia)",
        throatDryness: "Gal-Talushosha (Throat dryness)",
        palmSoleBurning: "Kara-Padatala Daha (Burning sensation in palm & soles)",
        legCramps: "Pindikodwestana (Leg cramps)"
    };

    const handleRadioChange = (symptom, value) => {
        setComplaints({
            ...complaints,
            [symptom]: { ...complaints[symptom], selected: value },
        });
    };

    const handleDurationChange = (complaint, value) => {
        setComplaints({
            ...complaints,
            [complaint]: { ...complaints[complaint], duration: value },
        });
    };

    const handleRadioChange2 = (complaint, value) => {
        setOtherComplaints({
            ...Othercomplaints,
            [complaint]: { ...Othercomplaints[complaint], selected: value },
        });
    };


    const handleDurationChange2 = (symptom, value) => {
        setOtherComplaints({
            ...Othercomplaints,
            [symptom]: { ...Othercomplaints[symptom], duration: value },
        });
    };

    const handleRadioChange3 = (disease, value) => {
        setDiseases({
            ...diseases,
            [disease]: { ...diseases[disease], selected: value },
        });
    };

    const handleDurationChange3 = (disease, value) => {
        setDiseases({
            ...diseases,
            [disease]: { ...diseases[disease], duration: value },
        });
    };



    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };



    return <>
        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 pt-5 rounded-lg dark:border-gray-700 mt-14">
                <div className='prescription_container'>
                    <div className='Forms mt-15'>
                        <h1>Case Sheet</h1>
                        <div className='content-box'>
                            <Box sx={{ width: '100%' }}>
                                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        if (isStepOptional(index)) {
                                            labelProps.optional = (
                                                <Typography variant="caption">Optional</Typography>
                                            );
                                        }
                                        if (isStepSkipped(index)) {
                                            stepProps.completed = false;
                                        }
                                        return (
                                            <Step key={label} {...stepProps} >
                                                <StepLabel StepIconComponent={ColorlibStepIcon} >
                                                    <p id='step'>{label}</p></StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                                {activeStep === steps.length ? (
                                    <>
                                        <div className='container'>

                                            <div className='client-basic-information'>
                                                <div className='last-section'>
                                                    <Fade><img className='last-section-img' src='ayurailogofull.png'>

                                                    </img>
                                                    </Fade>
                                                    <Slide><p id='last-section-p'>Thank you for choosing Ayur.AI</p></Slide>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        {activeStep === 0 ? <div className='container'>
                                            <Fade>
                                                <div className='client-basic-information'>
                                                    <div className='client-inputs'>
                                                        <div>
                                                            <p>Name</p>
                                                            <input type='text' id='name'
                                                                value={PatientDetails.Name}
                                                                onChange={(e) => {
                                                                    HandlePatientDetails("Name", e.target.value)
                                                                }}></input>
                                                        </div>
                                                        <div>
                                                            <p>Age</p>
                                                            <input type='text' id='name'
                                                                value={PatientDetails.Age}
                                                                onChange={(e) => {
                                                                    HandlePatientDetails("Age", e.target.value)
                                                                }}></input>
                                                        </div>
                                                        <div>
                                                            <p>Height</p>
                                                            <input type='text' id='name'
                                                                value={PatientDetails.Height}
                                                                onChange={(e) => {
                                                                    HandlePatientDetails("Height", e.target.value)
                                                                }}></input>
                                                        </div>
                                                        <div>
                                                            <p>Weight</p>
                                                            <input type='text' id='name'
                                                                value={PatientDetails.Weight}
                                                                onChange={(e) => {
                                                                    HandlePatientDetails("Weight", e.target.value)
                                                                }}></input>
                                                        </div>
                                                        <div>
                                                            <p>
                                                                Gender
                                                            </p>
                                                            <div style={{ display: 'flex', alignItems: "center", gap: "5px" }}>
                                                                <input type="radio" value="Male" checked={PatientDetails.Gender === 'Male' ? true : false} onClick={(e) => {
                                                                    HandlePatientDetails("Gender", e.target.value)
                                                                }} />
                                                                <label for="gender1">Male</label>
                                                                <input type="radio" value="Female" checked={PatientDetails.Gender === 'Female' ? true : false} onClick={(e) => {
                                                                    HandlePatientDetails("Gender", e.target.value)
                                                                }} />
                                                                <label for="gender2">Female</label>
                                                                <input type="radio" value="Others" checked={PatientDetails.Gender === 'Others' ? true : false} onClick={(e) => {
                                                                    HandlePatientDetails("Gender", e.target.value)
                                                                }} />
                                                                <label for="gender3">Others</label>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p>
                                                                Marital status
                                                            </p>
                                                            <select id='name'
                                                                value={PatientDetails.Maritalstatus}
                                                                onChange={(e) => {
                                                                    HandlePatientDetails("Maritalstatus", e.target.value)
                                                                }}>
                                                                <option value='Single'>Single</option>
                                                                <option value='Married'>Married</option>
                                                                <option value='Divorced'>Divorced</option>
                                                                <option value='Widowed'>Widowed</option>
                                                            </select>
                                                        </div>


                                                        <div>
                                                            <p>
                                                                Occupation
                                                            </p>
                                                            <input type='text' id='name'
                                                                value={PatientDetails.Occupation}
                                                                onChange={(e) => {
                                                                    HandlePatientDetails("Occupation", e.target.value)
                                                                }}></input>
                                                        </div>

                                                        <div>
                                                            <p>
                                                                Present Residence
                                                            </p>
                                                            <div style={{ display: 'flex', alignItems: "center", gap: "5px" }}>
                                                                <input type="radio" id="gender" name="gender" value="Urban" checked={PatientDetails.PresentResidence === "Urban" ? true : false} onClick={(e) => {
                                                                    HandlePatientDetails("PresentResidence", e.target.value)
                                                                }} />
                                                                <label for="gender1">Urban</label>
                                                                <input type="radio" id="gender" name="gender" value="Rural" checked={PatientDetails.PresentResidence === "Rural" ? true : false} onClick={(e) => {
                                                                    HandlePatientDetails("PresentResidence", e.target.value)
                                                                }} />
                                                                <label for="gender2">Rural</label>

                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p>
                                                                Birth Place
                                                            </p>
                                                            <div style={{ display: 'flex', alignItems: "center", gap: "5px" }}>
                                                                <input type="radio" value="Urban" checked={PatientDetails.BirthPlace === "Urban" ? true : false} onClick={(e) => {
                                                                    HandlePatientDetails("BirthPlace", e.target.value)
                                                                }} />
                                                                <label for="gender1">Urban</label>
                                                                <input type="radio" value="Rural" checked={PatientDetails.BirthPlace === "Rural" ? true : false} onClick={(e) => {
                                                                    HandlePatientDetails("BirthPlace", e.target.value)
                                                                }} />
                                                                <label for="gender2">Rural</label>

                                                            </div>
                                                        </div>
                                                        {/* <div>
                                                            <p>
                                                                Eye Sight
                                                            </p>
                                                            <input type='text' id='name'
                                                                value={PatientDetails.Occupation}
                                                                onChange={(e) => {
                                                                    HandlePatientDetails("Occupation", e.target.value)
                                                                }}></input>
                                                        </div> */}
                                                    </div>


                                                </div>
                                            </Fade>
                                        </div> : activeStep === 1 ?
                                            <div className='container'>
                                                <Fade>
                                                    <div className='client-basic-information'>

                                                        <div className='Medication'>
                                                            <div className='diagnois'>
                                                                <p>Complaints</p>

                                                                <div className='Current-medication'>
                                                                    {Object.entries(complaints).map(([symptom, { selected, duration }]) => (
                                                                        <div className='disorders-card'>
                                                                            <div>
                                                                                <p>{complaintsLabels[symptom]}</p>
                                                                                <div>
                                                                                    <input type="radio" name={symptom} value="Yes" checked={selected === 'Yes'} onChange={(e) => handleRadioChange(symptom, e.target.value)} />
                                                                                    <label for="html">Yes</label><br />
                                                                                    <input type="radio" name={symptom} value="No" checked={selected === 'No'} onChange={(e) => handleRadioChange(symptom, e.target.value)} />
                                                                                    <label for="QualityofSleep2">No</label><br></br>
                                                                                </div>
                                                                            </div>
                                                                            {selected === 'Yes' && (
                                                                                <div style={{ display: 'flex', flexDirection: "column", gap: '12px' }}>
                                                                                    <p style={{ fontWeight: 400 }}>
                                                                                        Enter Duration
                                                                                    </p>
                                                                                    <input
                                                                                        type="text"
                                                                                        style={{ borderRadius: '10px' }}
                                                                                        placeholder="Duration"
                                                                                        value={duration}
                                                                                        onChange={(e) => handleDurationChange(symptom, e.target.value)}
                                                                                    />
                                                                                </div>

                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className='diagnois'>
                                                                <p>Other Associated Complaints</p>

                                                                <div className='Current-medication'>
                                                                    {Object.entries(Othercomplaints).map(([symptom, { selected, duration }]) => (
                                                                        <div className='disorders-card'>
                                                                            <div>
                                                                                <p>{OthercomplaintLabels[symptom]}</p>
                                                                                <div>
                                                                                    <input type="radio" name={symptom} value="Yes" checked={selected === 'Yes'} onChange={(e) => handleRadioChange2(symptom, e.target.value)} />
                                                                                    <label for="html">Yes</label><br />
                                                                                    <input type="radio" name={symptom} value="No" checked={selected === 'No'} onChange={(e) => handleRadioChange2(symptom, e.target.value)} />
                                                                                    <label for="QualityofSleep2">No</label><br></br>
                                                                                </div>
                                                                            </div>
                                                                            {selected === 'Yes' && (
                                                                                <div style={{ display: 'flex', flexDirection: "column", gap: '12px' }}>
                                                                                    <p style={{ fontWeight: 400 }}>
                                                                                        Enter Duration
                                                                                    </p>
                                                                                    <input
                                                                                        type="text"
                                                                                        style={{ borderRadius: '10px' }}
                                                                                        placeholder="Duration"
                                                                                        value={duration}
                                                                                        onChange={(e) => handleDurationChange2(symptom, e.target.value)}
                                                                                    />
                                                                                </div>

                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className='diagnois'>
                                                                <p>Other Diseases</p>
                                                                <div className='Current-medication'>
                                                                    {Object.entries(diseases).map(([disease, { selected, duration }]) => (
                                                                        <div className='disorders-card'>
                                                                            <div>
                                                                                <p>{disease.replace(/([A-Z])/g, ' $1').trim()}</p>
                                                                                <div>
                                                                                    <input type="radio" name={disease} value="Yes" checked={selected === 'Yes'} onChange={(e) => handleRadioChange3(disease, e.target.value)} />
                                                                                    <label for="html">Yes</label><br />
                                                                                    <input type="radio" name={disease} value="No" checked={selected === 'No'} onChange={(e) => handleRadioChange3(disease, e.target.value)} />
                                                                                    <label for="QualityofSleep2">No</label><br></br>
                                                                                </div>
                                                                            </div>
                                                                            {selected === 'Yes' && (
                                                                                <div style={{ display: 'flex', flexDirection: "column", gap: '12px' }}>
                                                                                    <p style={{ fontWeight: 400 }}>
                                                                                        Enter Duration
                                                                                    </p>
                                                                                    <input
                                                                                        type="text"
                                                                                        style={{ borderRadius: '10px' }}
                                                                                        placeholder="Duration"
                                                                                        value={duration}
                                                                                        onChange={(e) => handleDurationChange3(disease, e.target.value)}
                                                                                    />
                                                                                </div>

                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>

                                                            <div className='diagnois'>
                                                                <p>History of past illness</p>
                                                                <textarea row={6} onChange={(e) => {
                                                                    setPastillness(e.target.value)
                                                                }}></textarea>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </Fade>
                                            </div> : activeStep === 2 ? <div className='container'>
                                                <Fade>
                                                    <div className='client-basic-information'>
                                                        <div className='Medication'>
                                                            <div className='Current-medication'>
                                                                <p style={{ marginTop: "1rem" }}>Diet</p>
                                                                <div className='client-inputs'>
                                                                    <div>
                                                                        <p>
                                                                            Type of diet
                                                                        </p>
                                                                        <div style={{ display: 'flex', alignItems: "center", gap: "1rem" }}>
                                                                            <input type="radio" id="gender1" name="gender" value="Vegetarian" onClick={(e) => {
                                                                                setDietType(e.target.value)
                                                                            }} />
                                                                            <label for="gender1">Vegetarian</label>
                                                                            <input type="radio" id="gender2" name="gender" value="Vegetarian" onClick={(e) => {
                                                                                setDietType(e.target.value)
                                                                            }} />
                                                                            <label for="gender2" >Non-Vegetarian</label>
                                                                            <label for="gender2" >Any other diet</label>
                                                                            <input type="text" id="gender2" name="gender" style={{ borderRadius: "12px" }} onChange={(e) => {
                                                                                setDietType(e.target.value)
                                                                            }} />

                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p>
                                                                            Agni
                                                                        </p>
                                                                        <div style={{ display: 'flex', alignItems: "center", gap: "1rem" }}>
                                                                            <input type="radio" id="Sama" name="Agni" value="Male" onClick={(e) => {
                                                                                setAgni(e.target.value)
                                                                            }} />
                                                                            <label for="gender1">Sama</label>
                                                                            <input type="radio" id="Vishama" name="Agni" value="Sama" onClick={(e) => {
                                                                                setAgni(e.target.value)
                                                                            }} />
                                                                            <label for="gender1">Vishama</label>
                                                                            <input type="radio" id="Manda" name="Agni" value='Vishama' onClick={(e) => {
                                                                                setAgni(e.target.value)
                                                                            }} />
                                                                            <label for="gender1">Manda</label>
                                                                            <input type="radio" id="Tikshna" name="Agni" value="Manda" onClick={(e) => {
                                                                                setAgni(e.target.value)
                                                                            }} />
                                                                            <label for="gender1">Tikshna </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <p>Habits</p>
                                                                    <div className='Current-medication'>
                                                                        {Object.entries(foodHabits).map(([habit, { selected, frequency }]) => (
                                                                            <div className='disorders-card'>
                                                                                <div>
                                                                                    <p>{habit.charAt(0).toUpperCase() + habit.slice(1)} </p>
                                                                                    <div>
                                                                                        <input type="radio" value="Yes" checked={selected === 'Yes'}
                                                                                            onChange={(e) => handleRadioChange4(habit, e.target.value)} />
                                                                                        <label for="html">Yes</label><br />
                                                                                        <input type="radio" value="No" checked={selected === 'No'}
                                                                                            onChange={(e) => handleRadioChange4(habit, e.target.value)} />
                                                                                        <label for="QualityofSleep2">No</label><br></br>
                                                                                    </div>

                                                                                </div>
                                                                                {selected === 'Yes' && (
                                                                                    <select
                                                                                        style={{ margin: "1rem" }}
                                                                                        value={frequency}
                                                                                        onChange={(e) => handleFrequencyChange4(habit, e.target.value)}
                                                                                    >
                                                                                        <option value="">Select Frequency</option>
                                                                                        <option value="Daily">Daily</option>
                                                                                        <option value="Occasionally">Occasionally</option>
                                                                                        <option value="Never">Never</option>
                                                                                    </select>
                                                                                )}

                                                                            </div>

                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <p>Addictions</p>
                                                                    <div className='Current-medication'>

                                                                        {Object.entries(Addictions).map(([habit, { selected, frequency }]) => (
                                                                            <div className='disorders-card'>
                                                                                <div>
                                                                                    <p>{habit.charAt(0).toUpperCase() + habit.slice(1)} </p>
                                                                                    <div>
                                                                                        <input type="radio" value="Yes" checked={selected === 'Yes'}
                                                                                            onChange={(e) => handleRadioChange5(habit, e.target.value)} />
                                                                                        <label for="html">Yes</label><br />
                                                                                        <input type="radio" value="No" checked={selected === 'No'}
                                                                                            onChange={(e) => handleRadioChange5(habit, e.target.value)} />
                                                                                        <label>No</label><br></br>
                                                                                    </div>

                                                                                </div>
                                                                                {selected === 'Yes' && (
                                                                                    <select
                                                                                        style={{ margin: "1rem" }}
                                                                                        value={frequency}
                                                                                        onChange={(e) => handleFrequencyChange5(habit, e.target.value)}
                                                                                    >
                                                                                        <option value="">Select Frequency</option>
                                                                                        <option value="Daily">Daily</option>
                                                                                        <option value="Occasionally">Occasionally</option>
                                                                                        <option value="Never">Never</option>
                                                                                    </select>
                                                                                )}

                                                                            </div>

                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <p>Physical activity</p>
                                                                    <div className='Current-medication'>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "auto", gap: "1rem" }}>
                                                                                <p>Exercise</p>
                                                                                <div >
                                                                                    <input type="radio" id="Exercise1" name="Exercise" value="Vigorous exercise or strenuous at work" onChange={(e) => { setExercise(e.target.value) }} />
                                                                                    <label for="html">Vigorous exercise or strenuous at work </label><br />
                                                                                    <input type="radio" id="Exercise1" name="Exercise" value="Moderate exercise at work/home" onChange={(e) => { setExercise(e.target.value) }} />
                                                                                    <label for="QualityofSleep2" >Moderate exercise at work/home</label><br></br>
                                                                                    <input type="radio" id="Exercise2" name="Exercise" value="Mild exercise at work/home" onChange={(e) => { setExercise(e.target.value) }} />
                                                                                    <label for="Exercise2">Mild exercise at work/home </label><br />
                                                                                    <input type="radio" id="Exercise3" name="Exercise" value="No exercise and sedentary at work/home" onChange={(e) => { setExercise(e.target.value) }} />
                                                                                    <label for="Exercise3">No exercise and sedentary at work/home</label><br></br>
                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <p>Sleep</p>
                                                                    <div className='Current-medication'>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "35rem" }}>
                                                                                <p>Quality of Sleep</p>
                                                                                <div >
                                                                                    <input type="radio" id="QualityofSleep1" name="QualityofSleep" value="Yes" onChange={(e) => { HandleSleepDetails('QualityOFSleep', e.target.value) }} />
                                                                                    <label for="QualityofSleep1">GOOD</label><br />
                                                                                    <input type="radio" id="QualityofSleep2" name="QualityofSleep" value="No" onChange={(e) => { HandleSleepDetails('QualityOFSleep', e.target.value) }} />
                                                                                    <label for="QualityofSleep2">POOR</label><br></br>
                                                                                    <input type="radio" id="html" name="QualityofSleep" value="Yes" onChange={(e) => { HandleSleepDetails('QualityOFSleep', e.target.value) }} />
                                                                                    <label for="html">MODERATE </label><br />
                                                                                    <input type="radio" id="QualityofSleep2" name="QualityofSleep" value="No" onChange={(e) => { HandleSleepDetails('QualityOFSleep', e.target.value) }} />
                                                                                    <label for="QualityofSleep2">DISTURBED</label><br></br>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='disorders-card'>
                                                                            <div style={{}}>
                                                                                <p>Diwaswapna(day sleep)</p>
                                                                                <div>
                                                                                    <input type="radio" id="Diwaswapna1" name="Diwaswapna" value="Yes" onChange={(e) => { HandleSleepDetails('Diwaswapna', e.target.value) }} />
                                                                                    <label for="Diwaswapna1">Yes</label><br />
                                                                                    <input type="radio" id="Diwaswapna2" name="Diwaswapna" value="No" onChange={(e) => { HandleSleepDetails('Diwaswapna', e.target.value) }} />
                                                                                    <label for="Diwaswapna2">No</label><br></br>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='disorders-card'>
                                                                            <div style={{}}>
                                                                                <p>Ratrijagarana</p>
                                                                                <div>
                                                                                    <input type="radio" id="Ratrijagarana1" name="Ratrijagarana" value="Yes" onChange={(e) => { HandleSleepDetails('Ratrijagarana', e.target.value) }} />
                                                                                    <label for="Ratrijagaran1">Yes</label><br />
                                                                                    <input type="radio" id="Ratrijagarana2" name="Ratrijagarana" value="No" onChange={(e) => { HandleSleepDetails('Ratrijagarana', e.target.value) }} />
                                                                                    <label for="Ratrijagarana2">No</label><br></br>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div className='diagnois'>
                                                                    <p>Occupational History</p>
                                                                    <div className='Current-medication'>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "24.9rem" }}>
                                                                                <p>Physical</p>
                                                                                <div >
                                                                                    <input type="radio" id="Physical1" name="Physical" value="Sedentary"
                                                                                        onChange={(e) => {
                                                                                            HandleOccupationalHistory('Physical', e.target.value)
                                                                                        }} />
                                                                                    <label for="Physical1">Sedentary</label><br />
                                                                                    <input type="radio" id="Physical2" name="Physical" value="Heavy" onChange={(e) => {
                                                                                        HandleOccupationalHistory('Physical', e.target.value)
                                                                                    }} />
                                                                                    <label for="Physical2">Heavy </label><br></br>
                                                                                    <input type="radio" id="Physical3" name="Physical" value="Active" onChange={(e) => {
                                                                                        HandleOccupationalHistory('Physical', e.target.value)
                                                                                    }} />
                                                                                    <label for="Physical3">Active</label><br />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='disorders-card'>
                                                                            <div >
                                                                                <p>Mental</p>
                                                                                <div >
                                                                                    <input type="radio" id="Mental1" name="Mental" value="Stressful" onChange={(e) => {
                                                                                        HandleOccupationalHistory('Mental', e.target.value)
                                                                                    }} />
                                                                                    <label for="Mental1">Stressful</label><br />
                                                                                    <input type="radio" id="Mental2" name="Mental" value="Un-stressful" onChange={(e) => {
                                                                                        HandleOccupationalHistory('Mental', e.target.value)
                                                                                    }} />
                                                                                    <label for="Mental2"> Un-stressful</label><br></br>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className='diagnois'>
                                                                    <p>Micturition</p>
                                                                    <div className='Current-medication'>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "24.9rem" }}>
                                                                                <p>Frequency </p>
                                                                                <div >
                                                                                    <input type="radio" id="Frequency1" name="Frequency" value="Low" onChange={(e) => {
                                                                                        setMicturition(e.target.value)
                                                                                    }} />
                                                                                    <label for="Frequency1">Low</label><br />
                                                                                    <input type="radio" id="Frequency2" name="Frequency" value="Medium" onChange={(e) => {
                                                                                        setMicturition(e.target.value)
                                                                                    }} />
                                                                                    <label for="Frequency2">medium</label><br></br>
                                                                                    <input type="radio" id="Frequency3" name="Frequency" value="High" onChange={(e) => {
                                                                                        setMicturition(e.target.value)
                                                                                    }} />
                                                                                    <label for="Frequency3">high</label><br />
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <p>Bowel</p>
                                                                    <div className='Current-medication'>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "32rem" }}>
                                                                                <p>Bowel movements</p>
                                                                                <div >
                                                                                    <input type="radio" id="Bowel1" name="Bowel" value="Regular" onChange={(e)=>{
                                                                                        HandleBowel('BowelMovements',e.target.value)
                                                                                    }} />
                                                                                    <label for="Bowel1">Regular </label><br />
                                                                                    <input type="radio" id="Bowel2" name="Bowel" value="Irregular" onChange={(e)=>{
                                                                                        HandleBowel('BowelMovements',e.target.value)
                                                                                    }} />
                                                                                    <label for="Bowel2">Irregular</label><br></br>
                                                                                    <input type="radio" id="Bowel3" name="Bowel" value="Constipated" onChange={(e)=>{
                                                                                        HandleBowel('BowelMovements',e.target.value)
                                                                                    }} />
                                                                                    <label for="Bowel3">Constipated</label><br />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "25rem" }}>
                                                                                <p>Koshta</p>
                                                                                <div >
                                                                                    <input type="radio" id="Koshta1" name="Koshta" value="Krura" onChange={(e)=>{
                                                                                        HandleBowel('Koshta',e.target.value)
                                                                                    }} />
                                                                                    <label for="Koshta1">Krura</label><br />
                                                                                    <input type="radio" id="Koshta2" name="Koshta" value="Madhyam"  onChange={(e)=>{
                                                                                        HandleBowel('Koshta',e.target.value)
                                                                                    }} />
                                                                                    <label for="Koshta2">Madhyam</label><br></br>
                                                                                    <input type="radio" id="Koshta3" name="Koshta" value="Mrudu"  onChange={(e)=>{
                                                                                        HandleBowel('Koshta',e.target.value)
                                                                                    }} />
                                                                                    <label for="Koshta3">Mrudu</label><br />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                             {PatientDetails.Gender!='Male'?  <><div className='diagnois'>
                                                                    <p>Gynecological History</p>
                                                                    <div className='Current-medication'>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "20rem" }}>
                                                                                <p >Menstrual Cycle</p>
                                                                                <div >
                                                                                    <input type="radio" id="MenstrualCycle1" name="MenstrualCycle" value="Regular" onChange={(e) => {
                                                                                        HandleGynecological('MenstrualCycle', e.target.value)
                                                                                    }} />
                                                                                    <label for="MenstrualCycle1">Regular </label><br />
                                                                                    <input type="radio" id="MenstrualCycle2" name="MenstrualCycle" value="Irregular" onChange={(e) => {
                                                                                        HandleGynecological('MenstrualCycle', e.target.value)
                                                                                    }} />
                                                                                    <label for="MenstrualCycle2">Irregular</label><br></br>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "25rem" }}>
                                                                                <p>Menstrual Flow</p>
                                                                                <div >
                                                                                    <input type="radio" id="MenstrualFlow1" name="MenstrualFlow" value="Scanty" onChange={(e) => {
                                                                                        HandleGynecological('MenstrualFlow', e.target.value)
                                                                                    }} />
                                                                                    <label for="MenstrualCycle1">Scanty</label><br />
                                                                                    <input type="radio" id="MenstrualFlow2" name="MenstrualFlow" value="Moderate" onChange={(e) => {
                                                                                        HandleGynecological('MenstrualCycle', e.target.value)
                                                                                    }} />
                                                                                    <label for="MenstrualCycle2">Moderate</label><br></br>
                                                                                    <input type="radio" id="MenstrualFlow3" name="MenstrualFlow" value="Heavy" onChange={(e) => {
                                                                                        HandleGynecological('MenstrualCycle', e.target.value)
                                                                                    }} />
                                                                                    <label for="MenstrualCycle3">Heavy</label><br />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='disorders-card'>
                                                                            <div >
                                                                                <p>Menopause</p>
                                                                                <div >
                                                                                    <input type="radio" id="Menopause1" name="Menopause" value="Yes" onChange={(e) => {
                                                                                        HandleGynecological('Menopause', e.target.value)
                                                                                    }} />
                                                                                    <label for="Menopause1">Yes</label><br />
                                                                                    <input type="radio" id="Menopause2" name="Menopause" value="No" />
                                                                                    <label for="Menopause2">No</label><br></br>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <p>Obstectrical History</p>
                                                                    <div className='Current-medication'>
                                                                        <div className='client-inputs'>
                                                                            <div>
                                                                                <p>No of Delivery</p>
                                                                                <input type='text' id='name'
                                                                                    value={ObstectricalHistory.Name}
                                                                                    onChange={(e) => {
                                                                                        HandleObstectricalHistory("NoOfDelivery", e.target.value)
                                                                                    }}></input>
                                                                            </div>
                                                                            <div>
                                                                                <p>
                                                                                    Type of Delivery
                                                                                </p>
                                                                                <div style={{ display: 'flex', alignItems: "center", gap: "5px" }}>
                                                                                    <input type="radio" id="deliverytype1" name="deliverytype" value="Normal" onClick={(e) => {
                                                                                        HandleObstectricalHistory("TypeofDelivery", e.target.value)
                                                                                    }} />
                                                                                    <label for="deliverytype1">Normal</label>
                                                                                    <input type="radio" id="deliverytype2" name="deliverytype" value="LSCS" onClick={(e) => {
                                                                                        HandleObstectricalHistory("TypeofDelivery", e.target.value)
                                                                                    }} />
                                                                                    <label for="deliverytype2">LSCS</label>
                                                                                    <input type="radio" id="deliverytype3" name="deliverytype" value="Forceps" onClick={(e) => {
                                                                                        HandleObstectricalHistory("TypeofDelivery", e.target.value)
                                                                                    }} />
                                                                                    <label for="deliverytype3">Forceps</label>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <p>
                                                                                    Hysterectomy
                                                                                </p>
                                                                                <div style={{ display: 'flex', alignItems: "center", gap: "5px" }}>
                                                                                    <input type="radio" id="Hysterectomy1" name="Hysterectomy" value="Yes" onClick={(e) => {
                                                                                        HandleObstectricalHistory("Hysterectomy", e.target.value)
                                                                                    }} />
                                                                                    <label for="Hysterectomy1">Yes</label>
                                                                                    <input type="radio" id="Hysterectomy2" name="Hysterectomy" value="No" onClick={(e) => {
                                                                                        HandleObstectricalHistory("Hysterectomy", e.target.value)
                                                                                    }} />
                                                                                    <label for="Hysterectomy2">No</label>

                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <p>Any other</p>
                                                                                <input type='text' id='name'
                                                                                    value={ObstectricalHistory.Name}
                                                                                    onChange={(e) => {
                                                                                        HandleObstectricalHistory("Anyother", e.target.value)
                                                                                    }}></input>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div></>:<></>}
                                                                <div className='diagnois'>
                                                                    <p>Surgeries</p>
                                                                    <textarea row={6} onChange={(e)=>{
                                                                        setSurgeries(e.target.value)
                                                                    }}></textarea>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </Fade>
                                            </div> : activeStep === 3 ?
                                                <div className='container'>
                                                    <Fade>
                                                        <div className='client-basic-information'>
                                                            <div className='Medication'>
                                                                <div className='Current-medication'>
                                                                    <div className='diagnois'>
                                                                        <p>Prakriti</p>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "25rem" }}>
                                                                                <p>Dominant</p>
                                                                                <div >
                                                                                    <input type="radio" id="Dominant1" name="Dominan" value="Vata" onChange={(e)=>{
                                                                                        HandlePrakriti('Dominant',e.target.value)
                                                                                    }} />
                                                                                    <label for="html">Vata </label><br />
                                                                                    <input type="radio" id="Dominant2" name="Dominan" value="Pitta" onChange={(e)=>{
                                                                                        HandlePrakriti('Dominant',e.target.value)
                                                                                    }}/>
                                                                                    <label for="QualityofSleep2">Pitta</label><br></br>
                                                                                    <input type="radio" id="Dominant3" name="Dominan" value="Kapha" onChange={(e)=>{
                                                                                        HandlePrakriti('Dominant',e.target.value)
                                                                                    }} />
                                                                                    <label for="QualityofSleep2">Kapha</label><br></br>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "25rem" }}>
                                                                                <p>Assoicated</p>
                                                                                <div >
                                                                                    <input type="radio" id="Assoicated1" name="Assoicated" value="Vata" onChange={(e)=>{
                                                                                        HandlePrakriti('Assoicated',e.target.value)
                                                                                    }}/>
                                                                                    <label for="html">Vata </label><br />
                                                                                    <input type="radio" id="Assoicated2" name="Assoicated" value="Pitta" onChange={(e)=>{
                                                                                        HandlePrakriti('Assoicated',e.target.value)
                                                                                    }} />
                                                                                    <label for="QualityofSleep2">Pitta</label><br></br>
                                                                                    <input type="radio" id="Assoicated3" name="Assoicated" value="Kapha" onChange={(e)=>{
                                                                                        HandlePrakriti('Assoicated',e.target.value)
                                                                                    }} />
                                                                                    <label for="QualityofSleep2">Kapha</label><br></br>
                                                                                </div>
                                                                            </div>

                                                                            <div className='disorders-card'>
                                                                                <p>Vata</p>
                                                                                &nbsp;&nbsp;
                                                                                <PrettoSlider
                                                                                    valueLabelDisplay="auto"
                                                                                    aria-label="pretto slider"
                                                                                    value={PrakritiVata}
                                                                                    onChange={(e,values)=>{
                                                                                       setPrakritiVata(values)
                                                                                    }}
                                                                                  
                                                                                />

                                                                            </div>
                                                                            <div className='disorders-card'>
                                                                                <p>Pitta</p>
                                                                                &nbsp;&nbsp;
                                                                                <PrettoSlider1
                                                                                    valueLabelDisplay="auto"
                                                                                    aria-label="pretto slider"
                                                                                    values={PrakritiPitta}
                                                                                    onChange={(e,values)=>{
                                                                                       setPrakritiPitta(values)
                                                                                    }}
                                                                             
                                                                                />

                                                                            </div>
                                                                            <div className='disorders-card'>
                                                                                <p>Kapha</p>
                                                                                &nbsp;&nbsp;
                                                                                <PrettoSlider2
                                                                                    valueLabelDisplay="auto"
                                                                                    aria-label="pretto slider"
                                                                                    value={PrakritiKapha}
                                                                                    onChange={(e,values)=>{
                                                                                        setPrakritiKapha(values)
                                                                                    }}
                                                                                  
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='diagnois'>
                                                                        <p>Dosham</p>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "25rem" }}>
                                                                                <p>Decreased</p>
                                                                                <div >
                                                                                    <input type="radio" id="Decreased1" name="Decreased" value="Vata" onChange={(e)=>{
                                                                                        HandleDosham('Decreased',e.target.value)
                                                                                    }} />
                                                                                    <label for="html">Vata </label><br />
                                                                                    <input type="radio" id="Decreased2" name="Decreased" value="pitta" onChange={(e)=>{
                                                                                        HandleDosham('Decreased',e.target.value)
                                                                                    }} />
                                                                                    <label for="QualityofSleep2">Pitta</label><br></br>
                                                                                    <input type="radio" id="Decreased3" name="Decreased" value="Kapha" onChange={(e)=>{
                                                                                        HandleDosham('Decreased',e.target.value)
                                                                                    }}/>
                                                                                    <label for="QualityofSleep2">Kapha</label><br></br>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "25rem" }}>
                                                                                <p>Increased</p>
                                                                                <div >
                                                                                    <input type="radio" id="Increased1" name="Increased" value="Vata" onChange={(e)=>{
                                                                                        HandleDosham('Increased',e.target.value)
                                                                                    }}/>
                                                                                    <label for="html">Vata </label><br />
                                                                                    <input type="radio" id="Increased2" name="Increased" value="Pitta" onChange={(e)=>{
                                                                                        HandleDosham('Increased',e.target.value)
                                                                                    }} />
                                                                                    <label for="QualityofSleep2">Pitta</label><br></br>
                                                                                    <input type="radio" id="Increased3" name="Increased" value="Kapha" onChange={(e)=>{
                                                                                        HandleDosham('Increased',e.target.value)
                                                                                    }}/>
                                                                                    <label for="QualityofSleep2">Kapha</label><br></br>
                                                                                </div>
                                                                            </div>


                                                                            <div className='disorders-card'>
                                                                                <p>Vata</p>
                                                                                &nbsp;&nbsp;
                                                                                <PrettoSlider
                                                                                    valueLabelDisplay="auto"
                                                                                    aria-label="pretto slider"
                                                                                    value={DoshamVata}
                                                                                    onChange={(e,values)=>{
                                                                                      setDoshamVata(values)
                                                                                    }}
                                                                                    
                                                                                />

                                                                            </div>
                                                                            <div className='disorders-card'>
                                                                                <p>Pitta</p>
                                                                                &nbsp;&nbsp;
                                                                                <PrettoSlider1
                                                                                    valueLabelDisplay="auto"
                                                                                    aria-label="pretto slider"
                                                                                    value={DoshamPitta}
                                                                                    onChange={(e,value)=>{
                                                                                       setDoshamPitta(value)
                                                                                    }}
                                                                                    
                                                                                />

                                                                            </div>
                                                                            <div className='disorders-card'>
                                                                                <p>Kapha</p>
                                                                                &nbsp;&nbsp;
                                                                                <PrettoSlider2
                                                                                    valueLabelDisplay="auto"
                                                                                    aria-label="pretto slider"
                                                                                    value={DoshamKapha}
                                                                                    onChange={(e,values)=>{
                                                                                      setDoshamKapha(values)
                                                                                    }}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Fade>
                                                </div> : activeStep === 4 ? <div className='container'>
                                                    <Fade>
                                                        <div className='client-basic-information'>
                                                            <div className='Medication'>
                                                                <div className='Current-medication'>
                                                                    <p>Ayurverda diagnosis</p>
                                                                    <textarea height={'10rem'} onChange={(e)=>{
                                                                        setAyurvedaDiagnois(e.target.value)
                                                                    }}></textarea>
                                                                    <p>Modern diagnosis</p>
                                                                    <textarea height={'10rem'} onChange={(e)=>{
                                                                        setModernDiagnois(e.target.value)
                                                                    }}></textarea>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                </div> : <></>}
                                        <div className='action-buttons'>
                                            <div className='action-buttons-box'>
                                                <Button
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                {isStepOptional(activeStep) && (
                                                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                        Skip
                                                    </Button>
                                                )}

                                                <Button onClick={handleNext} style={{ backgroundImage: 'linear-gradient(to top, #00c6fb 0%, #005bea 100%)', color: 'white' }}>
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default CaseSheet
